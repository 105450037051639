import { useState } from "react";
import Checkbox, { CheckboxI } from "../Checkbox/Checkbox";
import ListSection from "../ListSection/ListSection";

interface Props {
  options: CheckboxI[];
  title: string;
  forKey: string;
  onSelect: (selected: string[]) => void;
  selectAllOption?: boolean;
}

function CheckboxList({
  options,
  title,
  forKey,
  onSelect,
  selectAllOption,
}: Props) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const selectAllElement: CheckboxI = {
    value: 'selectAll',
    title: 'All'
  }
  function onChangeHandler(item: CheckboxI) {
    let updatedSelectedOptions;
    if (selectedOptions.includes(item.value)) {
      updatedSelectedOptions = selectedOptions.filter(
        (selected) => selected !== item.value
      );
    } else {
      updatedSelectedOptions = [...selectedOptions, item.value];
    }
    setSelectedOptions(updatedSelectedOptions);
    onSelect(updatedSelectedOptions);
  }

  function selectAllHandler() {
    let updatedSelectedOptions: string[];
    if(!selectedAll) {
      updatedSelectedOptions = options.map(option => {
        return option.value
      });
    }
    else {
      updatedSelectedOptions = []
    }
    setSelectedOptions(updatedSelectedOptions)
    onSelect(updatedSelectedOptions);
    setSelectedAll(!selectedAll)
    
  }

  return (
    <ListSection title={title}>
      {selectAllOption && (
        <Checkbox
          key={`selectAll_${forKey}`}
          checkbox={selectAllElement}
          onChange={selectAllHandler}
        ></Checkbox>
      )}
      {options.map((checkbox) => (
        <Checkbox
          key={forKey + checkbox.value}
          checkbox={checkbox}
          onChange={onChangeHandler}
          checkAll={selectedAll}
        ></Checkbox>
      ))}
    </ListSection>
  );
}

export default CheckboxList;
