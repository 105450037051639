export const getAllowedRoles = (userRole: string) => {
	let result;
	result = [
		{
			value: "operator",
			label: "Operator",
		},
		{
			value: "user",
			label: "User",
		},
		{
			value: "clientadmin",
			label: "Client Admin",
		},
	];
	return result;
};

export module UserRoles {
	export const VIEWER = "user";
	export const OPERATOR = "operator";
	export const CLIENT_ADMIN = "clientadmin";
	export const ADMIN = "admin";
}
