import { User } from "dtos/User";
import { FaEdit, FaUnlink } from "react-icons/fa";
import { BiDoorOpen } from "react-icons/bi";
import styled from "styled-components";

const IconText = styled.span`
	@media (max-width: 1024px) {
		display: none;
	}
`;

interface Props {
	user: User;
	viewUser: (user: User) => void;
	moveUser: (user: User) => void;
	unlinkUser: (user: User) => void;
}

const UserRow: React.FC<Props> = ({ user, viewUser, moveUser, unlinkUser }) => {
	return (
		<div className="Rtable Rtable--6cols">
			<div className="Rtable-cell">{user.userName}</div>
			<div className="Rtable-cell">{user.roles[0]}</div>
			<div className="Rtable-cell only-wide-screen">{user.email}</div>
			<div className="Rtable-cell only-wide-screen">{user.firstName}</div>
			<div className="Rtable-cell only-wide-screen">{user.lastName}</div>
			<div className="Rtable-cell">
				<span
					title="Move to"
					className="button is-success is-light is-outlined"
					onClick={() => {
						moveUser(user);
					}}
				>
					<BiDoorOpen size={20}></BiDoorOpen>
				</span>
				<span
					title="Edit User"
					style={{ marginLeft: "2px" }}
					className="button is-primary is-light is-outlined"
					onClick={() => {
						viewUser(user);
					}}
				>
					<FaEdit size={20}></FaEdit>
				</span>
				<span
					title="Unlink User"
					style={{ marginLeft: "2px" }}
					className="button is-danger is-light is-outlined"
					onClick={() => {
						unlinkUser(user);
					}}
				>
					<FaUnlink size={20}></FaUnlink>
				</span>
			</div>
		</div>
	);
};

export default UserRow;
