import EditableParagraph from "components/EditableParagraph";
import useMount from "customhooks/useMount";
import { AssignmentArea } from "dtos";
import { selectedTable as selectedTableAtom } from "myrecoil/AssignmentState";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useRecoilState } from "recoil";

interface Props {
    areas: AssignmentArea[];
}

const TableNav: React.FC<Props> = ({ areas }) => {
    const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);

    const selectFirstTable = () => {
        for (var a = 0; a < areas.length; ++a)
        {
            for (var r = 0; r < areas[a].rows.length; ++r)
            {
                if (areas[a].rows[r].tables.length > 0)
                {
                    setSelectedTable({
                        table: areas[a].rows[r].tables[0],
                        area: a,
                        row: r,
                        col: 0,
                    });
                    return;
                }
            }
        }
    };

    const isEmptyRow = (aIdx: number, rIdx: number) => {
        if (aIdx >= 0 && aIdx < areas.length)
        {
            if (rIdx >= 0 && rIdx < areas[aIdx].rows.length)
            {
                return areas[aIdx].rows[rIdx].tables.length == 0;
            }
        }
        return true;
    };

    const isEmptyArea = (aIdx: number) => {
        if (aIdx >= 0 && aIdx < areas.length)
        {
            for (var r = 0; r < areas[aIdx].rows.length; ++r)
            {
                if (!isEmptyRow(aIdx, r))
                {
                    return false;
                }
            }
        }
        return true;
    };

    const isEmptyMap = () => {
        for (var a = 0; a < areas.length; ++a)
        {
            if (!isEmptyArea(a))
            {
                return false;
            }
        }
        return true;
    };

    useMount(() => {
        if (!isEmptyMap() && !selectedTable) {
            selectFirstTable();
        }
    });

    const prevArea = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable)
        {
            selectFirstTable();
            return;
        }
        for (var a = selectedTable.area + areas.length - 1; a >= selectedTable.area; --a)
        {
            let aIdx = a % areas.length;
            if (!isEmptyArea(aIdx))
            {
                for (var r = areas[aIdx].rows.length - 1; r >=0; --r)
                {
                    if (!isEmptyRow(selectedTable.area, r))
                    {
                        setSelectedTable({
                            table: areas[aIdx].rows[r].tables[areas[aIdx].rows[r].tables.length - 1],
                            area: selectedTable.area,
                            row: r,
                            col: areas[aIdx].rows[r].tables.length - 1,
                        });
                        return;
                    }
                }
            }
        }
        // all prev areas empty -- do nothing
    };

    const nextArea = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable)
        {
            selectFirstTable();
            return;
        }
        // find the next non-empty area
        for (var a = selectedTable.area + 1; a <= (selectedTable.area + areas.length); ++a)
        {
            let aIdx = a % areas.length;
            if (!isEmptyArea(aIdx))
            {
                // find the first non-empty row in the area
                for (var r = 0; r < areas[aIdx].rows.length; ++r)
                {
                    if (!isEmptyRow(selectedTable.area, r))
                    {
                        setSelectedTable({
                            table: areas[aIdx].rows[r].tables[0],
                            area: selectedTable.area,
                            row: r,
                            col: 0,
                        });
                        return;
                    }
                }
            }
        }
        // all next areas empty - do nothing
    };

    const nextRow = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable)
        {
            selectFirstTable();
            return;
        }
        let area = areas[selectedTable.area];
        // find the next non-empty row
        for (var r = selectedTable.row + 1; r < area.rows.length; ++r)
        {
            if (!isEmptyRow(selectedTable.area, r))
            {
                setSelectedTable({
                    table: area.rows[r].tables[0],
                    area: selectedTable.area,
                    row: r,
                    col: 0,
                });
                return;
            }
        }
        // all next rows empty
        nextArea();
    };

    const prevRow = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable)
        {
            selectFirstTable();
            return;
        }
        let area = areas[selectedTable.area];
        if (selectedTable.row === 0) {
            prevArea();
        }
        for (var r = selectedTable.row - 1; r >= 0; --r)
        {
            if (!isEmptyRow(selectedTable.area, r))
            {
                setSelectedTable({
                    table: area.rows[r].tables[area.rows[r].tables.length - 1],
                    area: selectedTable.area,
                    row: r,
                    col: area.rows[r].tables.length - 1,
                });
                return;
            }
        }
        // all prev rows empty
        prevArea();
    };

    const nextTable = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable) {
            selectFirstTable();
        } else {
            let area = areas[selectedTable.area];
            if (
                selectedTable.col ===
                area.rows[selectedTable.row].tables.length - 1
            ) {
                nextRow();
            } else {
                setSelectedTable({
                    table: area.rows[selectedTable.row].tables[
                        selectedTable.col + 1
                    ],
                    area: selectedTable.area,
                    row: selectedTable.row,
                    col: selectedTable.col + 1,
                });
            }
        }
    };

    const prevTable = () => {
        if (isEmptyMap())
        {
            return;
        }
        if (!selectedTable) {
            selectFirstTable();
        } else {
            let area = areas[selectedTable.area];
            if (selectedTable.col === 0) {
                prevRow();
            } else {
                setSelectedTable({
                    table: area.rows[selectedTable.row].tables[
                        selectedTable.col - 1
                    ],
                    area: selectedTable.area,
                    row: selectedTable.row,
                    col: selectedTable.col - 1,
                });
            }
        }
    };

    const setRow = (rowStr: string) => {
        if (isEmptyMap())
        {
            return false;
        }
        let area = areas[selectedTable!.area];
        try {
            let rowIdx = Number.parseInt(rowStr) - 1;
            if (rowIdx >= 0 && rowIdx < area.rows.length) {
                let row = area.rows[rowIdx];
                let colIdx =
                    row.tables.length > selectedTable!.col
                        ? selectedTable!.col
                        : 0;
                setSelectedTable({
                    table: row.tables[colIdx],
                    area: selectedTable!.area,
                    row: rowIdx,
                    col: colIdx,
                });
                return true;
            }
        } catch (e) {}
        return false;
    };

    const setCol = (colStr: string) => {
        if (isEmptyMap())
        {
            return false;
        }
        let area = areas[selectedTable!.area];
        try {
            let colIdx = Number.parseInt(colStr) - 1;
            if (
                colIdx >= 0 &&
                colIdx < area.rows[selectedTable!.row].tables.length
            ) {
                setSelectedTable({
                    table: area.rows[selectedTable!.row].tables[colIdx],
                    area: selectedTable!.area,
                    row: selectedTable!.row,
                    col: colIdx,
                });
                return true;
            }
        } catch (e) {}
        return false;
    };

    const setArea = (areaStr: string) => {
        if (isEmptyMap())
        {
            return false;
        }
        try {
            let areaIdx = Number.parseInt(areaStr) - 1;
            if (
                areaIdx >= 0 &&
                areaIdx < areas.length &&
                !isEmptyArea(areaIdx)
            ) {
                for (var r = 0; r < areas[areaIdx].rows.length; ++r)
                {
                    if (areas[areaIdx].rows[r].tables.length > 0)
                    {
                        setSelectedTable({
                            table: areas[areaIdx].rows[r].tables[0],
                            area: areaIdx,
                            row: r,
                            col: 0,
                        });
                        return true;
                    }
                }
            }
        } catch (e) {}
        return false;
    }

    return (
        <>
            {!isEmptyMap() && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                    }}
                >
                    <button
                        onClick={prevTable}
                        className="button is-info is-inverted"
                        title="Prev Table"
                    >
                        <FaAngleDoubleLeft></FaAngleDoubleLeft>
                    </button>
                    <div
                        style={{
                            fontSize: "1.25rem",
                            margin: "0 auto",
                            display: "inline-block",
                        }}
                    >
                        {selectedTable && (
                            <div>
                                <b>Area: </b>
                                <EditableParagraph
                                    value={selectedTable.area + 1 + ""}
                                    setValue={setArea}
                                ></EditableParagraph>
                                <b>Row: </b>
                                <EditableParagraph
                                    value={selectedTable.row + 1 + ""}
                                    setValue={setRow}
                                ></EditableParagraph>
                                <b>Table: </b>
                                <EditableParagraph
                                    value={selectedTable.col + 1 + ""}
                                    setValue={setCol}
                                ></EditableParagraph>{" "}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={nextTable}
                        className="button is-info is-inverted"
                        title="Next Table"
                    >
                        <FaAngleDoubleRight></FaAngleDoubleRight>
                    </button>
                </div>
            )}
        </>
    );
};

export default TableNav;
