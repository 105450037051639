export const fileFormats: Array<{ title: string; value: string }> = [
  { title: "PDF", value: "PDF" },
  { title: "XLSX", value: "XLSX" },
  { title: "DOCX", value: "DOCX" },
];

export const typeOfDmg: Array<{ title: string; value: string }> = [
  { title: "Soiling", value: "PANEL_BROKEN_DIRT" },
  { title: "Module Cracking", value: "PANEL_BROKEN_CRACKING" },
  { title: "Multiple Hot Cells", value: "PANEL_BROKEN_MULTICELL" },
  { title: "Bypass Diode", value: "PANEL_BROKEN_DIODE" },
  { title: "Hot cell", value: "PANEL_BROKEN_CELL" },
  { title: "Vegetation/Shadowing", value: "PANEL_BROKEN_VEGETATION" },
  { title: "Sun reflection", value: "PANEL_BROKEN_SUNREFLECTION" },
  { title: "Hot module", value: "PANEL_BROKEN_MODULE" },
  { title: "Multiple hot module", value: "PANEL_BROKEN_MULTIMODULE" },
  { title: "Missing module", value: "PANEL_BROKEN_MISSING" },
  { title: "Other problem", value: "PANEL_BROKEN_OTHER" },
];

export const additionalSettings: Array<{ title: string; value: string }> = [
  { title: "Without Images", value: "NoImages" },
];
