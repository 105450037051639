import styled from "styled-components";

const Container = styled.div`
  font-family: sans-serif;
  text-align: center;
  height: 70vh;
`;

const MapContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default MapContainer;
