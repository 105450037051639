import { useTenantsApi } from "api/TenantsAPI";
import Box from "components/Box";
import useMount from "customhooks/useMount";
import { Tenant, User } from "dtos";
import { theTenant } from "myrecoil/TenantState";
import { useState } from "react";
import Select from "react-select";
import { useRecoilValue } from "recoil";

interface Props {
	user: User | null;
	closeModal: () => void;
	moveUser: (user: User, to: string) => void;
}

const MoveUserModal: React.FC<Props> = ({ user, closeModal, moveUser }) => {
	const [toTenant, setToTenant] = useState<Tenant | null>(null);
	const [tenants, setTenants] = useState<Tenant[]>([]);
	const currentTenant = useRecoilValue(theTenant);
	const tenantsApi = useTenantsApi();

	useMount(() => {
		tenantsApi
			.getAllTenants()
			.then((tens) =>
				setTenants(
					tens.filter((tenant) => tenant.id !== currentTenant?.id)
				)
			);
	});

	return (
		<div className={`modal ${user ? " is-active is-clipped" : ""}`}>
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">
						{`Move ${user?.userName} to`}
					</p>
					<button
						className="delete"
						aria-label="close"
						onClick={closeModal}
					></button>
				</header>
				<section className="modal-card-body">
					<Box>
						<div className="row pt-4">
							<Select
								placeholder={"Select a Company"}
								getOptionLabel={(tenant: Tenant) => tenant.name}
								getOptionValue={(tenant: Tenant) => tenant.id}
								name="typeSelect"
								value={toTenant}
								onChange={(tenant: Tenant | null) => {
									setToTenant(tenant);
								}}
								options={tenants}
							></Select>
						</div>
						<div className="row mt-5 is-flex is-justify-content-space-between">
							<button
								className="mr-2 button is-primary is-outlined"
								onClick={() => {
									moveUser(user!, toTenant!.id);
									closeModal();
								}}
								disabled={!toTenant}
							>
								Move
							</button>
						</div>
					</Box>
				</section>
			</div>
		</div>
	);
};

export default MoveUserModal;
