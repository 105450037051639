import Spinner from "components/Spinner";
import {useApi} from "api/API"
import { useMediaQuery } from "customhooks/useMediaQuery";
import { Mission, PatrolConfig } from "dtos";
import { useEffect } from "react";
import { useState } from "react";
import viewConstants from "fixtures/patrolmodal.json";

const labelStyle = {
  color: "#363636",
  display: "block",
  fontSize: "1rem",
  fontWeight: "bold",
} as const;

interface Props {
  mission: Mission;
  setMission: (mission: Mission) => void;
  isActive: boolean;
  closeModal: () => void;
}

const PatrolModal: React.FC<Props> = ({
  mission,
  setMission,
  isActive,
  closeModal,
}) => {
  const [patrolConfig, setPatrolConfig] = useState<PatrolConfig>({
    vehicleScoreThreshold: 0.5,
    personScoreThreshold: 0.5,
    followDistance: 0,
    relativeAltitude: 0,
    targetLostTimeouts: 0,
  } as PatrolConfig);
  const [followPerson, setFollowPerson] = useState<boolean>(
    patrolConfig.personScoreThreshold >= 0.5
  );
  const [followVehicle, setFollowVehicle] = useState<boolean>(
    patrolConfig.vehicleScoreThreshold >= 0.5
  );
  const [loading, setLoading] = useState<boolean>(true);

  const api = useApi();
  
  useEffect(() => {
    if (mission.patrolConfigId) {
      api
        .get(`patrolconfig/${mission.patrolConfigId}`)
        .then(({ data }) => {
          if (data) {
            setPatrolConfig(data);
            setFollowPerson(data.personScoreThreshold >= 0.5);
            setFollowVehicle(data.vehicleScoreThreshold >= 0.5);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [isActive]);
  const breakpoints = useMediaQuery();
  return (
    <>
      {loading ? (
        <Spinner mrgTop="65px"></Spinner>
      ) : (
        <div className={`modal ${isActive ? " is-active is-clipped" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Patrol Options</p>
              <button
                className="delete"
                aria-label="close"
                onClick={closeModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <div className="notification is-info is-light">
                <ul style={{ textAlign: "start", listStyle: "initial" }}>
                  {viewConstants.description.map((content, index) => {
                    return <li key={index}>{content}</li>;
                  })}
                </ul>
              </div>
              <div
                className={`p-5   ${
                  breakpoints.md
                    ? " is-flex is-justify-content-space-around is-flex-wrap-wrap"
                    : ""
                }`}
              >
                <div className="is-flex">
                  <div style={{ width: "49%" }} className="mb-2  p-3 ">
                    <div className="pretty p-default p-round p-thick p-smooth mb-2">
                      <input
                        type="checkbox"
                        defaultChecked={followPerson}
                        onClick={() => {
                          setPatrolConfig({
                            ...patrolConfig,
                            personScoreThreshold: followPerson ? 0 : 0.5,
                          });
                          setFollowPerson((prev) => !prev);
                        }}
                      />
                      <div className="state p-primary-o">
                        <label>Follow Person</label>
                      </div>
                    </div>
                    {followPerson && (
                      <div className="field  is-flex is-align-items-center">
                        <label style={labelStyle}>
                          Person Score Threshold:{" "}
                        </label>
                        <input
                          style={{ width: "100px", marginRight: "5px" }}
                          className="input "
                          type="number"
                          step="0.1"
                          min="0.5"
                          max="1"
                          value={patrolConfig.personScoreThreshold}
                          placeholder="Person Score Threshold"
                          onChange={(event) => {
                            let num = Number(event.target.value);
                            setPatrolConfig({
                              ...patrolConfig,
                              personScoreThreshold: num,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "49%" }} className="mb-2  p-3 ">
                    <div className="pretty p-default p-round p-thick p-smooth mb-2">
                      <input
                        type="checkbox"
                        defaultChecked={followVehicle}
                        onClick={() => {
                          setPatrolConfig({
                            ...patrolConfig,
                            vehicleScoreThreshold: followVehicle ? 0 : 0.5,
                          });
                          setFollowVehicle((prev) => !prev);
                        }}
                      />
                      <div className="state p-primary-o">
                        <label>Follow Vehicle</label>
                      </div>
                    </div>
                    {followVehicle && (
                      <div className="field  is-flex is-align-items-center">
                        <label style={labelStyle}>
                          Vehicle Score Threshold:{" "}
                        </label>
                        <input
                          style={{ width: "100px", marginRight: "5px" }}
                          className="input "
                          type="number"
                          step="0.1"
                          min="0.5"
                          max="1"
                          value={patrolConfig.vehicleScoreThreshold}
                          placeholder="Vehicle Score Threshold"
                          onChange={(event) => {
                            let num = Number(event.target.value);
                            setPatrolConfig({
                              ...patrolConfig,
                              vehicleScoreThreshold: num,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ minWidth: "200px" }} className="field">
                  <label className="label">Follow Distance: </label>
                  <input
                    className="input "
                    type="number"
                    value={patrolConfig.followDistance}
                    placeholder="Follow Distance"
                    onChange={(event) => {
                      let num = Number(event.target.value);
                      setPatrolConfig({ ...patrolConfig, followDistance: num });
                    }}
                  ></input>
                </div>
                <div style={{ minWidth: "200px" }} className="field">
                  <label className="label">
                    Followed target's relative altitute:{" "}
                  </label>
                  <input
                    className="input "
                    type="number"
                    value={patrolConfig.relativeAltitude}
                    placeholder="Relative Altitude in meters"
                    onChange={(event) => {
                      let num = Number(event.target.value);
                      setPatrolConfig({
                        ...patrolConfig,
                        relativeAltitude: num,
                      });
                    }}
                  ></input>
                </div>
                <div style={{ minWidth: "200px" }} className="field">
                  <label className="label">Target is lost timeout: </label>
                  <input
                    className="input "
                    type="number"
                    value={patrolConfig.targetLostTimeouts}
                    placeholder="Timeout of when to return when a target is lost"
                    onChange={(event) => {
                      let num = Number(event.target.value);
                      setPatrolConfig({
                        ...patrolConfig,
                        targetLostTimeouts: num,
                      });
                    }}
                  ></input>
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-primary is-outlined"
                onClick={() => {
                  if (!patrolConfig.id) {
                    setMission({ ...mission, patrolConfig });
                  } else {
                    api
                      .patch(`patrolconfig/${patrolConfig.id}`, patrolConfig)
                      .then((result) => {
                        console.log(result);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                  closeModal();
                }}
              >
                Save changes
              </button>
              <button
                className="button is-danger is-outlined"
                onClick={closeModal}
              >
                Cancel
              </button>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default PatrolModal;
