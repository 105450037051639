import { UserModel } from "api/API";
import { userAtom } from "myrecoil/UserState";
import LoginPage from "pages/LoginPage";
import MainPage from "pages/MainPage";
import ReportDesignerPage from "pages/ReportDesignerPage";
import { FC } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRecoilValue } from "recoil";

const AppWithAuth: FC = () => {
	const user= useRecoilValue<UserModel | null>(
		userAtom
	);

	return (
		<Switch>
			{user && <MainPage></MainPage>}
			<Route path="/signin" component={LoginPage} />
			<Route path="/reportdesigner" component={ReportDesignerPage} />
			<Redirect to="/signin" />
		</Switch>
	);
};

export default AppWithAuth;
