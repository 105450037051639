import { Drone } from "dtos";
import React from "react";

interface Props {
  drone: Drone;
  setDrone: (drone: Drone) => void;
}

const OnvifSettings: React.FC<Props> = ({ drone, setDrone }) => {
  return (
    <div className="box">
      <div className="is-flex is-justify-content-center is-flex-direction-column">
        <div className="mb-5 is-flex is-flex-wrap-wrap">
          <div className="">
            <div className="pretty p-switch">
              <input
                type="checkbox"
                name="switch1"
                checked={drone.onvifSettings?.enabled}
                onChange={() => {
                  setDrone({
                    ...drone,
                    onvifSettings: {
                      ...drone.onvifSettings,
                      enabled: !drone.onvifSettings.enabled,
                    },
                  });
                }}
              />
              <div className="state p-success">
                <label>
                  {drone.onvifSettings?.enabled ? "Enabled" : "Disabled"}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div style={{ width: "266px" }} className="field ">
            <label className="label">Service Port: </label>
            <input
              className="input "
              type="text"
              value={drone.onvifSettings.servicePort}
              onChange={(event) => {
                try {
                  setDrone({
                    ...drone,
                    onvifSettings: {
                      ...drone.onvifSettings,
                      servicePort: Number(event.target.value),
                    },
                  });
                } catch {}
              }}
            ></input>
          </div>
        </div>
        <div className="is-flex is-flex-wrap-wrap">
          <div style={{ minWidth: "266px" }} className="field mr-5 ">
            <label className="label">Username: </label>
            <input
              className="input "
              type="text"
              value={drone.onvifSettings.userName}
              placeholder="Username"
              onChange={(event) => {
                setDrone({
                  ...drone,
                  onvifSettings: {
                    ...drone.onvifSettings,
                    userName: event.target.value,
                  },
                });
              }}
            ></input>
          </div>
          <div style={{ minWidth: "266px" }} className="field">
            <label className="label">Password: </label>
            <input
              className="input "
              type="password"
              placeholder="Password"
              value={drone.onvifSettings.password}
              onChange={(event) => {
                setDrone({
                  ...drone,
                  onvifSettings: {
                    ...drone.onvifSettings,
                    password: event.target.value,
                  },
                });
              }}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnvifSettings;
