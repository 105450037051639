import {useApi} from "api/API"
import useMount from "customhooks/useMount";
import { DroneNetworkSettings } from "dtos/DroneNetworkSettings";
import React, { useState } from "react";
import Spinner from "components/Spinner";

import { FaWifi } from "react-icons/fa";
import NetworkSettingsModal from "./NetworkSettingsModal";

interface Props {
  droneId: string;
}

const NetworkSettings: React.FC<Props> = ({ droneId }) => {
  const [curNetwork, setCurNetwork] = useState<DroneNetworkSettings | null>(
    null
  );

  const [droneNetworks, setDroneNetworks] = useState<DroneNetworkSettings[]>(
    []
  );
  const [globalNetworks, setGlobalNetworks] = useState<DroneNetworkSettings[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const api = useApi();

  const getNetworks = () => {
    setLoading(true);
    Promise.all([
      api.get(`drones/${droneId}/dronenetworksettings`),
      api.get("networksettings"),
    ])
      .then((results) => {
        let droneNets = results[0].data as DroneNetworkSettings[];
        let globalNets = results[1].data;
        if (droneNets) {
          setDroneNetworks(droneNets);
        }
        if (globalNets) {
          let contains = (net: DroneNetworkSettings) => {
            if (droneNets) {
              for (let i = 0; i < droneNets.length; i++) {
                if (droneNets[i].id === net.id) return true;
              }
            }
            return false;
          };
          setGlobalNetworks(
            globalNets.filter((net: DroneNetworkSettings) => {
              return droneNets && !contains(net);
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(e.toString());
        setLoading(false);
      });
  };

  useMount(() => {
    getNetworks();
  });

  const onViewNetworkClick = (network: DroneNetworkSettings) => {
    setCurNetwork(network);
  };

  const saveNetwork = (network: DroneNetworkSettings) => {
    if (network.id) {
      api.patch(`networksettings/${network.id}`, network).then(() => {
        getNetworks();
      });
    } else {
      api.post(`networksettings`, network).then(() => {
        getNetworks();
      });
    }
  };

  const deleteNetwork = (network: DroneNetworkSettings) => {
    if (network.id) {
      api.delete(`networksettings/${network.id}`).then(() => {
        getNetworks();
        setCurNetwork(null);
      });
    }
  };

  const createNewNetwork = () => {
    setCurNetwork({
      name: "",
      ssId: "",
      useDhcp: false,
      encryption: "",
      password: "",
      ipAddress: "",
      subnetMask: "",
      router: "",
      dnsHostname: "",
      order: 0,
    } as DroneNetworkSettings);
  };

  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : error ? (
        <div style={{width: "100%", textAlign: "center"}}>{error}</div>
      ) : (
        <div className="box p-5">
          <span
            className="button is-primary is-light is-outlined"
            onClick={createNewNetwork}
          >
            <span className="mr-2">Create New </span>
            <FaWifi></FaWifi>
          </span>
          {curNetwork && (
            <NetworkSettingsModal
              selectedNetwork={curNetwork}
              saveNetwork={saveNetwork}
              closeModal={() => setCurNetwork(null)}
              deleteNetwork={deleteNetwork}
            ></NetworkSettingsModal>
          )}

          <div className="is-flex  is-flex-direction-row pt-4">
            <div className="column is-half">
              <div className="title" style={{ marginBottom: "0px" }}>
                Drone's Networks:
              </div>
              <ul className=" list-items box">
                {droneNetworks.map((network) => {
                  return (
                    <li
                      key={network.id}
                      className="is-flex p-5 is-justify-content-space-between"
                    >
                      <div className="is-flex list-items_initials is-align-items-center">
                        <FaWifi className="mr-2"></FaWifi>
                        <h4>{network.ssId}</h4>
                      </div>
                      <div>
                        <button
                          className="button"
                          onClick={() => {
                            api
                              .patch(
                                `drones/${droneId}/dronenetworksettings/${network.id}/remove`,
                                {}
                              )
                              .then(() => {
                                getNetworks();
                              });
                          }}
                        >
                          Remove
                        </button>
                        <button
                          className="button"
                          onClick={() => onViewNetworkClick(network)}
                        >
                          Edit
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="column is-half  ">
              <div className="title" style={{ marginBottom: "0px" }}>
                Available Networks:
              </div>
              <ul className=" list-items box">
                {globalNetworks.map((network) => {
                  return (
                    <li
                      key={network.id}
                      className="is-flex p-5 is-justify-content-space-between"
                    >
                      <div className="is-flex list-items_initials is-align-items-center">
                        <FaWifi className="mr-2"></FaWifi>
                        <h4>{network.ssId}</h4>
                      </div>
                      <div>
                        <button
                          className="button"
                          onClick={() => {
                            api
                              .patch(
                                `drones/${droneId}/dronenetworksettings/${network.id}/add`,
                                {}
                              )
                              .then(() => {
                                getNetworks();
                              });
                          }}
                        >
                          Add
                        </button>
                        <button
                          className="button"
                          onClick={() => onViewNetworkClick(network)}
                        >
                          Edit
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NetworkSettings;
