import { useTenantsApi } from "api/TenantsAPI";
import CompanyModal from "components/admin/CompanyModal";
import Spinner from "components/Spinner";
import TenantRow from "components/TenantRow";
import useMount from "customhooks/useMount";
import { Tenant } from "dtos";
import { useState } from "react";
import { RiOrganizationChart } from "react-icons/ri";

const CompaniesView: React.FC = () => {
	const [companies, setCompanies] = useState<Tenant[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [selectedCompany, setSelectedCompany] = useState<Tenant | null>(null);
	const tenantsApi = useTenantsApi();

	const myGetAllTenants = () => {
		setLoading(true);
		tenantsApi
			.getAllTenants()
			.then((allTenants) => {
				setCompanies(allTenants);
				setLoading(false);
				setError(null);
			})
			.catch((error) => {
				setLoading(false);
				if (404 === error.response?.status) {
					setError("No users.");
				} else {
					setError(error.toString());
				}
			});
	};

	useMount(() => {
		myGetAllTenants();
	});

	return (
		<div className="container ">
			<CompanyModal
				company={selectedCompany}
				saveCompany={(company: Tenant) => {
					return tenantsApi.saveTenant(company).then(() => {
						myGetAllTenants();
						setSelectedCompany(null);
					});
				}}
				closeModal={() => {
					setSelectedCompany(null);
				}}
			></CompanyModal>
			<div className="buttons">
				<span
					className="button is-primary is-light is-outlined"
					onClick={() => {
						setSelectedCompany({} as Tenant);
					}}
				>
					<span>Add New Company</span>
					<RiOrganizationChart
						size={20}
						className="ml-2"
					></RiOrganizationChart>
				</span>
			</div>
			<div className="Rtable header Rtable--4cols">
				<div className="Rtable-cell">Company Name</div>
				<div className="Rtable-cell">Email</div>
				<div className="Rtable-cell only-wide-screen">Description</div>
				<div className="Rtable-cell">Options</div>
			</div>
			{loading ? (
				<Spinner mrgTop="65px"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				companies.map((company: Tenant) => {
					return (
						<TenantRow
							key={company.id}
							tenant={company}
							viewTenant={(company: Tenant) =>
								setSelectedCompany(company)
							}
							deleteTenant={(company: Tenant) => {
								tenantsApi.deleteTenant(company);
								myGetAllTenants();
							}}
						></TenantRow>
					);
				})
			)}
		</div>
	);
};

export default CompaniesView;
