import Box from "components/Box";
import { User } from "dtos/User";
import { getAllowedRoles } from "helpers/UserUtils";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import Select from "react-select";

interface Props {
	user: User | null;
	closeModal: () => void;
	saveUser: (user: User) => Promise<void>;
	deleteUser: (user: User) => void;
}

const UserModal: React.FC<Props> = ({
	user,
	closeModal,
	saveUser,
	deleteUser,
}) => {
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [userName, setUserName] = useState<string>("");
	const [role, setRole] = useState<string>("user");
	const [usernameError, setUsernameError] = useState<string | null>(null);
	const [emailError, setEmailError] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	const resetErrors = () => {
		setUsernameError(null);
		setEmailError(null);
		setError(null);
	};

	useEffect(() => {
		if (user && user.id) {
			setUserName(user.userName);
			setFirstName(user.firstName);
			setLastName(user.lastName);
			setEmail(user.email);
			setRole(user.roles[0]);
		} else {
			setUserName("");
			setFirstName("");
			setLastName("");
			setEmail("");
			setRole("user");
		}
		return () => {
			resetErrors();
		};
	}, [user]);

	return (
		<div className={`modal ${user ? " is-active is-clipped" : ""}`}>
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">
						{user?.id ? user.firstName : "Create New User"}
					</p>
					<button
						className="delete"
						aria-label="close"
						onClick={closeModal}
					></button>
				</header>
				{error ? (
					<div style={{ width: "100%", textAlign: "center" }}>
						{error}
					</div>
				) : (
					<section className="modal-card-body">
						<Box>
							<div className="row pt-4">
								<div className="columns">
									<div className="column is-half">
										<div className="field">
											<label className="label">
												User Name:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={userName}
												placeholder="Name"
												onChange={(event) => {
													setUserName(
														event.target.value
													);
												}}
											></input>
											{usernameError && (
												<p className="help is-danger mt-0">
													{usernameError}
												</p>
											)}
										</div>
										<div className="field">
											<label className="label">
												First Name:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={firstName}
												placeholder="Name"
												onChange={(event) => {
													setFirstName(
														event.target.value
													);
												}}
											></input>
										</div>
										<div className="field">
											<label className="label">
												Role:{" "}
											</label>
											<Select
												value={{
													value: role,
													label: role,
												}}
												onChange={(value) => {
													if (value)
														setRole(value.value);
												}}
												options={getAllowedRoles("")}
											></Select>
										</div>
									</div>
									<div className="column is-half">
										<div className="field">
											<label className="label">
												Email:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={email}
												placeholder="Name"
												onChange={(event) => {
													setEmail(
														event.target.value
													);
												}}
											></input>
											{emailError && (
												<p className="help is-danger mt-0">
													{emailError}
												</p>
											)}
										</div>
										<div className="field">
											<label className="label">
												Last Name:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={lastName}
												placeholder="tag"
												onChange={(event) => {
													setLastName(
														event.target.value
													);
												}}
											></input>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-5 is-flex is-justify-content-space-between">
								<button
									onClick={() => {
										resetErrors();
										saveUser({
											...user,
											firstName,
											lastName,
											email,
											userName,
											roles: [role],
										} as User).catch((error) => {
											if (
												Array.isArray(
													error?.response?.data
												)
											) {
												error.response.data.forEach(
													(e: {
														Message: string;
													}) => {
														if (
															/username/i.test(
																e.Message
															)
														) {
															setUsernameError(
																e.Message
															);
														} else if (
															/email/i.test(
																e.Message
															)
														) {
															setEmailError(
																e.Message
															);
														} else {
															setError(
																error.toString()
															);
														}
													}
												);
											} else {
												setError(error.toString());
											}
										});
									}}
									className="mr-2 button is-primary is-outlined"
								>
									Save
								</button>
								<span
									className="button is-danger is-light is-outlined"
									onClick={() => {
										deleteUser(user!);
										closeModal();
									}}
								>
									Delete
									<FaTrash size={20}></FaTrash>
								</span>
							</div>
						</Box>
					</section>
				)}
			</div>
		</div>
	);
};

export default UserModal;
