import Box from "components/Box";
import { Assignment, SiteInfo } from "dtos/Assignment";
import { useState } from "react";

const labelStyle = {
  color: "#363636",
  display: "block",
  fontSize: "1rem",
  fontWeight: "bold",
} as const;

interface Props {
    selectedAssignment: Assignment | null;
    saveAssignment: (assignment: Assignment | null) => void;
    closeModal: () => void;
}
const SetPowerPerAssignmentModal : React.FC<Props> = ({
    saveAssignment,
    selectedAssignment,
    closeModal
}) => {
    const [assignment, setAssignment] = useState<Assignment | null>(selectedAssignment );

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (assignment !== null)
        {
            setAssignment({ ...assignment, siteInfo: { ...assignment.siteInfo, [evt.target.name]: evt.target.value } });
        }
    };

  const onSave = () => {
      saveAssignment(assignment);
  };

  return (
    <div className="modal is-active is-clipped">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Power Settings</p>
          <button
            className="delete"
                      aria-label="close"
                      onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
        <Box>
            <div className="row">
               <div className="field ">
                    <label style={labelStyle}>Power per panel (W)</label>
                    <input
                     className="input "
                     type="number"
                     name="ratedPowerW"
                     value={assignment?.siteInfo?.ratedPowerW}
                              onChange={handleChange}
                    />
              </div>
            </div>
            <div className="row">
              <div className="field ">
                <label style={labelStyle}>Price symbol prefix: </label>
                <input
                  className="input "
                  type="text"
                  name="priceSymbolPrefix"
                  value={assignment?.siteInfo?.priceSymbolPrefix}
                  placeholder="Price prefix symbol"
                              onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="field">
                <label style={labelStyle}>Price per kW: </label>
                <input
                  className="input "
                  type="number"
                  value={assignment?.siteInfo?.pricePerKWH}
                    name="pricePerKWH"
                    placeholder="pricePerKWH"
                    onChange={handleChange}
                ></input>
               </div>
            </div>
            <div className="row">
              <div className="field ">
                <label style={labelStyle}>Price Symbol Suffix : </label>
                          <input
                              className="input "
                              type="text"
                              value={assignment?.siteInfo?.priceSymbolSuffix}
                              placeholder="Price suffix symbol"
                              onChange={handleChange}
                              name="priceSymbolSuffix"
                            />
              </div>
                      </div>
        </Box>
        </section>
        <footer className="modal-card-foot">
          <div
            className="row mt-5 is-flex is-justify-content-space-between"
            style={{ width: "100%" }}
          >
            <div>
              <button
                className="button is-primary is-outlined"
                onClick={onSave}
              >
                Save changes
              </button>
                <button className="button is-danger is-outlined" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SetPowerPerAssignmentModal;
