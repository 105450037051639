import React from "react";

const Footer: React.FC = () => {
	return (
		<div className="is-align-self-flex-end is-flex is-justify-content-space-between is-align-items-center moon_footer py-3 ">
			<div className="pl-3">Moonlimit AAR. &copy; 2024</div>
			<div className="is-flex moon_footer__contacts">
				<div className="mx-3">
					website:{" "}
					<a href="http://www.moonlimit.com">www.moonlimit.com</a>
				</div>
				<div className="mx-3">
					email:{" "}
					<a href="mailto: office@moonlimit.com">
						office@moonlimit.com
					</a>
				</div>
				<div className="mx-3">phone: +359 893 218 645</div>
			</div>
		</div>
	);
};

export default Footer;
