import { Area } from "dtos";
import { AreaTypes, getAreaColor } from "helpers/MissionAreaUtils";
import { useEffect, useState } from "react";

interface Props {
	targetArea: Area;
	updateArea: (newArea: Area, editedArea: Area) => void;
}

const AreaAltitudeComponent: React.FC<Props> = ({ targetArea, updateArea }) => {
	const [max, setMax] = useState<string>(targetArea.maxAltitude + "");
	const [min, setMin] = useState<string>(targetArea.minAltitude + "");
	const [target, setTarget] = useState<string>(
		targetArea.targetAltitude + ""
	);
	const [principalHeading, setPrincipalHeading] = useState<string>(
		targetArea.principalHeading + ""
	);
	useEffect(() => {
		setMax(targetArea.maxAltitude + "");
		setMin(targetArea.minAltitude + "");
		setTarget(targetArea.targetAltitude + "");
		setPrincipalHeading(targetArea.principalHeading + "");
	}, [targetArea]);

	const getViewObject = (kind: number | string) => {
		if (kind === AreaTypes.MISSION.num ||
			kind === AreaTypes.MISSION.str) {
			return {
				targetAltitudeLabel: "Mission Altitude",
				hasTargetAltitude: true,
				minAltitudeLabel: "Mission Min Altitude",
				maxAltitudeLabel: "Mission Max Altitude",
				principalHeadingLabel: "",
				hasPrincipalHeading: false,
				bgColor: "inherit",
			};
		} else if (kind === AreaTypes.AVOID.num ||
				   kind === AreaTypes.AVOID.str) {
			return {
				targetAltitudeLabel: "",
				hasTargetAltitude: false,
				minAltitudeLabel: "Avoid Area Min Altitude",
				maxAltitudeLabel: "Avoid Area Max Altitude",
				principalHeadingLabel: "",
				hasPrincipalHeading: false,
				bgColor: getAreaColor(kind),
			};
		} else {
			return {
				targetAltitudeLabel: "Interest Area Altitude",
				hasTargetAltitude: true,
				minAltitudeLabel: "Interest Area Min Altitude",
				maxAltitudeLabel: "Interest Area Max Altitude",
				principalHeadingLabel: "Principal Heading Angle",
				hasPrincipalHeading: true,
				bgColor: getAreaColor(kind),
			};
		}
	};

	let viewObj = getViewObject(targetArea.kind);
	
	return (
		<>
			{ viewObj.hasTargetAltitude === true && 
				<div style={{ minWidth: "266px" }} className="field">
					<label className="label">{viewObj.targetAltitudeLabel}: </label>
					<input
						className="input "
						type="number"
						style={{ backgroundColor: viewObj.bgColor }}
						value={target}
						placeholder={viewObj.targetAltitudeLabel}
						onChange={(event) => {
							if (event.target.value !== "") {
								let num = Number(event.target.value);
								updateArea(
									{ ...targetArea, targetAltitude: num },
									targetArea
								);
							} else {
								setTarget("");
							}
						}}
					></input>
				</div> 
			}
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.minAltitudeLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={min}
					placeholder={viewObj.minAltitudeLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							if (viewObj.hasTargetAltitude !== true) {
								// the target altitude for avoid areas is not editable
								// we always set it as the midpoint between min and max altitudes
								updateArea(
									{ ...targetArea, minAltitude: num, targetAltitude: 0.5*(num + targetArea.maxAltitude) },
									targetArea
								);
							} else {
								updateArea(
									{ ...targetArea, minAltitude: num },
									targetArea
								);
							}
						} else {
							setMin("");
						}
					}}
				></input>
			</div>
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.maxAltitudeLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={max}
					placeholder={viewObj.maxAltitudeLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							if (viewObj.hasTargetAltitude !== true) {
								// the target altitude for avoid areas is not editable
								// we always set it as the midpoint between min and max altitudes
								updateArea(
									{ ...targetArea, maxAltitude: num, targetAltitude: 0.5*(num + targetArea.minAltitude) },
									targetArea
								);
							} else {
								updateArea(
									{ ...targetArea, maxAltitude: num },
									targetArea
								);
							}
						} else {
							setMax("");
						}
					}}
				></input>
			</div>
			{/* Show the principal heading only if this area has one */}
			{ viewObj.hasPrincipalHeading === true &&
			<div style={{ minWidth: "266px" }} className="field">
				<label className="label">{viewObj.principalHeadingLabel}: </label>
				<input
					className="input "
					type="number"
					style={{ backgroundColor: viewObj.bgColor }}
					value={principalHeading}
					min="-90"
					max="90"
					step="1"
					placeholder={viewObj.principalHeadingLabel}
					onChange={(event) => {
						if (event.target.value !== "") {
							let num = Number(event.target.value);
							updateArea(
								{ ...targetArea, principalHeading: num },
								targetArea
							);
						} else {
							setPrincipalHeading("");
						}
					}}
				></input>
			</div> }
		</>
	);
};

export default AreaAltitudeComponent;
