import { Constants } from "helpers/Constants";
import { theTenant } from "myrecoil/TenantState";
import { userAtom } from "myrecoil/UserState";
import { useRecoilValue } from "recoil";

export const useReportDownload = () => {
	const tenant = useRecoilValue(theTenant);
	const theUser = useRecoilValue(userAtom);

	return {
		download: (token: string) => {
			let href = `${Constants.apiRoot}/api/${
				Constants.apiVersion
			}/tenants/${tenant!.id}/users/${
				theUser!.sub
			}/reports/download?token=${token}`;
			let element = document.createElement("a");
			element.setAttribute("href", href);
			element.setAttribute("download", "");

			element.style.display = "none";
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},
	};
};
