import AdminNavBar from "components/admin/AdminNavBar";
import { Redirect, Route, Switch } from "react-router";
import CompaniesView from "views/CompaniesView";

const AdminPage: React.FC = () => {
	return (
		<section className="hero">
			<div className="hero-head dynamic-theme-header">
				<AdminNavBar></AdminNavBar>
			</div>
			<div className="hero-body dynamic-theme-body ">
				<main className="container is-align-self-flex-start">
                    <Switch>
                        <Route path="/admin/companies" component={CompaniesView} />
                        <Redirect to="/admin/companies" />
                    </Switch>
				</main>
			</div>
		</section>
	);
};

export default AdminPage;
