import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import MapContainer from "components/map/MapContainer";
import { Assignment } from "dtos/Assignment";
import Table from "./Table";
import AreaMapView from "./AreaMapView";

interface Props {
	assignment: Assignment;
}
const AssignmentMap: React.FC<Props> = ({ assignment }) => {

	const getFirstNonEmptyArea = () => {
		if (isEmptyMap()) {
			return null;
		}
        for (var a = 0; a < assignment?.areas.length; ++a)
        {
			if (!isEmptyArea(a))
			{
				return assignment?.areas[a];
			}
        }
    };

	const isEmptyRow = (aIdx: number, rIdx: number) => {
        if (aIdx >= 0 && aIdx < assignment?.areas.length)
        {
            if (rIdx >= 0 && rIdx < assignment?.areas[aIdx].rows.length)
            {
                return assignment?.areas[aIdx].rows[rIdx].tables.length == 0;
            }
        }
        return true;
    };

	const isEmptyArea = (aIdx: number) => {
        if (aIdx >= 0 && aIdx < assignment?.areas.length)
        {
            for (var r = 0; r < assignment?.areas[aIdx].rows.length; ++r)
            {
                if (!isEmptyRow(aIdx, r))
                {
                    return false;
                }
            }
        }
        return true;
    };

	const isEmptyMap = () => {
        for (var a = 0; a < assignment?.areas.length; ++a)
        {
            if (!isEmptyArea(a))
            {
                return false;
            }
        }
        return true;
    };

	const calcInitialMapCenter = () => {
		if (isEmptyMap())
		{
			return {
				lat: 43.21564543532796,
				lng: 24.161250213801168,
		  	};
		}
		let areaMaybe = getFirstNonEmptyArea();
		if (!areaMaybe)
		{
			return {
				lat: 43.21564543532796,
				lng: 24.161250213801168,
		  	};
		}
		let firtsAreaRows = areaMaybe.rows;
		let result = null;
		if (firtsAreaRows) {
			let middleRow = firtsAreaRows[Math.floor(firtsAreaRows.length / 2)];
			result = middleRow.gps_Polygon[middleRow.gps_Polygon.length - 1];
		}
		return result
			? result
			: {
					lat: 43.21564543532796,
					lng: 24.161250213801168,
			  };
	};

	const onMapLoad = (map: any) => {
		// map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
		// 	document.getElementById("map-legend-id")
		// );
		map.setCenter(calcInitialMapCenter());
		map.setZoom(18);
	};

	return (
		<MapContainer>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "left",
				}}
			>
				<div style={{ marginRight: "15px" }}>
					<div
						className="legendbox"
						style={{ border: "3px solid green" }}
					></div>
					Inspected Solar Tables
				</div>
				<div>
					<div
						className="legendbox"
						style={{ border: "3px solid crimson" }}
					></div>
					Damaged Solar Tables
				</div>
			</div>
			<LoadScript
				id="script-loader"
				googleMapsApiKey="AIzaSyBIb_o3iZLT7EBD00W3ZvStycZ7W9zrPHk"
				language="en"
				region="us"
			>
				<GoogleMap
					mapContainerClassName="google_map_container"
					mapTypeId="satellite"
					onLoad={onMapLoad}
				>
					{assignment.mission.areas.filter(f => f.kind == "Interest" || f.kind==2).map((area, areaIndex) => {
						return (
							<AreaMapView key={area.id} areaInfo={area}></AreaMapView>
						);
					})}
					{assignment.areas.map((area, areaIndex) => {
						return area.rows.map((row, rowIndex) => {
							let rowLastPosition =
								row.gps_Polygon[row.gps_Polygon.length - 1];
							return (
								<div key={`Row ${rowIndex}`}>
									{row.tables.map((table, tableIndex) => {
										return (
											<Table
												key={table.id}
												table={table}
												area={areaIndex}
												row={rowIndex}
												col={tableIndex}
											></Table>
										);
									})}
									<OverlayView
										key={`RowLabel ${rowIndex}`}
										position={rowLastPosition}
										mapPaneName={OverlayView.OVERLAY_LAYER}
									>
										<div style={{ color: "white" }}>
											<b>{`Row ${rowIndex + 1}`}</b>
										</div>
									</OverlayView>
								</div>
							);
						});
					})}
				</GoogleMap>
			</LoadScript>
		</MapContainer>
	);
};

export default AssignmentMap;
