import Select from "react-select";
import Spinner from "components/Spinner";
import { AssignmentPanel, AssignmentPanelsLayout } from "dtos";
import UserRoleGate from "gates/UserRoleGate";
import { getInspectionStatusLabel } from "helpers/AssignmentUtils";
import {
	loadingAssignmentAtom,
	loadingAssignmentErrorAtom,
} from "myrecoil/AssignmentState";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

interface Props {
	panelsLayout: AssignmentPanelsLayout;
	panels: AssignmentPanel[];
	imgPrefix: string;
	updatePanel: (panel: AssignmentPanel) => void;
}

const PanelsDetails: React.FC<Props> = ({
	panels,
	panelsLayout,
	imgPrefix,
	updatePanel,
}) => {
	const [panel, setPanel] = useState<AssignmentPanel | null>(null);
	const isLoadingAssignment = useRecoilValue(loadingAssignmentAtom);
	const [loadingAssignmentError, setLoadingAssignmentError] = useRecoilState(
		loadingAssignmentErrorAtom
	);
	useEffect(() => () => setLoadingAssignmentError(null), []);
	const allStatuses =
		[
			{ value: "PANEL_BROKEN_OTHER", label: "Other Problem"},
			{ value: "PANEL_BROKEN_CELL", label: "Hot cell"},
			{ value: "PANEL_BROKEN_MULTICELL", label: "Multiple hot cells"},
			{ value: "PANEL_BROKEN_DIODE", label: "Bypass Diode"},
			{ value: "PANEL_BROKEN_MODULE", label: "Hot module"},
			{ value: "PANEL_BROKEN_MULTIMODULE", label: "Multiple hot modules"},
			{ value: "PANEL_BROKEN_VEGETATION", label: "Vegetation problem"},
			{ value: "PANEL_BROKEN_SUNREFLECTION", label: "Sun reflection"},
			{ value: "PANEL_BROKEN_DIRT", label: "Soiling"},
			{ value: "PANEL_BROKEN_CRACKING", label: "Module cracking"},
			{ value: "PANEL_BROKEN_MISSING", label: "Missing module"},
			{ value: "NotInspected", label: "Not Inspected"},
			{ value: "Ok", label: "Ok"}
		];


	const calcTableColumns = (cols: number) => {
		return Math.min(12, cols);
	};

	return (
		<>
			{isLoadingAssignment ? (
				<Spinner mrgTop="10em"></Spinner>
			) : loadingAssignmentError ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{loadingAssignmentError}
				</div>
			) : (
				<div>
					<div style={{ marginBottom: "5px" }}>
						{[...Array(panelsLayout.rows)].map((x, rowIdx) => {
							return (
								<div
									style={{
										width: "100%",
										display: "grid",
										gridTemplateColumns: `repeat(${calcTableColumns(
											panelsLayout.cols
										)}, auto`,
									}}
								>
									{[...Array(panelsLayout.cols)].map(
										(x, colIdx) => {
											let panelIdx =
												rowIdx * panelsLayout.cols +
												colIdx;
											let curPanel = panels[panelIdx];
											if (!curPanel) {
												console.log(
													panels,
													panelIdx,
													panelsLayout.cols
												);
											}
											return (
												<div
													key={
														curPanel.id +
														curPanel.inspectionStatus
													}
													style={{
														border: "1px solid black",
														margin: "0px",
														padding: "0px",
														textAlign: "center",
														cursor: "pointer",
														color:
															curPanel?.inspectionStatus ===
															"Ok"
																? "darkblue"
																: "crimson",
														backgroundColor:
															curPanel.id ===
															panel?.id
																? "var(--selected-background-color)"
																: "transparent",
													}}
													onClick={() =>
														setPanel(curPanel)
													}
												>
													{`${rowIdx + 1}.${
														colIdx + 1
													}`}
												</div>
											);
										}
									)}
								</div>
							);
						})}
					</div>
					{panel && (
						<>
							<p
								style={{
									fontSize: "1.25rem",
									marginRight: "5px",
								}}
							>
								<b>Serial number: </b>
								Not collected.
							</p>
							<p
								style={{
									fontSize: "1.25rem",
									marginRight: "5px",
								}}
							>
								<b>Panel inspection status: </b>
								<span
									className={`tag is-outlined is-light is-large ${
										panel.inspectionStatus === "Ok"
											? "is-primary"
											: "is-danger"
									}`}
								>
									{getInspectionStatusLabel(
										panel.inspectionStatus
									)}
								</span>
							</p>
						</>
					)}
					{panel &&
						(panel.ir_Images === 0 && panel.rgb_Images === 0 ? (
							<div>
								<span
									style={{
										marginBottom: "0.5em",
										paddingLeft: "0px",
									}}
									className={`tag is-outlined is-light is-large is-danger`}
								>
									No Images for this panel.
								</span>
							</div>
						) : (
							<div>
								{panel.rgb_Images > 0 && (
									<img
										alt=""
										src={`${imgPrefix}/panel_${
											panel.id
										}_RGB_${panel.rgb_Images - 1}.png`}
										className="panel-img"
									/>
								)}
								{panel.ir_Images > 0 && (
									<img
										className="panel-img"
										alt=""
										src={`${imgPrefix}/panel_${
											panel.id
										}_IR_${panel.ir_Images - 1}.png`}
									/>
								)}
							</div>
						))}
					{panel && (
						<UserRoleGate
							allowedRoles={["admin"]}
							AlternativeComponents={() => null}>
												<Select
													className="select-drop change-status"
													name="panelStatusSelect"
													value={allStatuses.find((status) => status.value == panel.inspectionStatus)}
													onChange={(e) => { if (e) { setPanel({ ...panel, inspectionStatus: e.value }); } }}
													options={allStatuses}
												/>
												<button
													className="button is-info is-light is-outlined mr-1 change-status-button"
													onClick={() => {
														updatePanel(panel);
													}}
												>
													Change status
											</button>
						</UserRoleGate>
					)}
				</div>
			)}
		</>
	);
};

export default PanelsDetails;
