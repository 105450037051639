import { useApi } from "api/API";
import AreaView from "components/map/AreaView";
import BaseView from "components/map/BaseView";
import DrawingTool, { DrawingProps } from "components/map/DrawingTool";
import MapContainer from "components/map/MapContainer";
import MapView from "components/map/MapView";
import { AreaAltitudeComponent, PatrolModal } from "components/mission";
import Spinner from "components/Spinner";
import { useMediaQuery } from "customhooks/useMediaQuery";
import { Area, Mission, Point } from "dtos";
import viewConstants from "fixtures/missionview.json";
import {
	AreaTypes,
	generateAreaID,
	getAreaColor,
} from "helpers/MissionAreaUtils";
import {
	DATA_COLLECT,
	getMissionType,
	MissionType,
	PATROL,
	SOLAR_PARK_MAP,
} from "helpers/MissionTypes";
import focusedDroneBaseIcon from "public/images/flag-focused.png";
import droneBaseIcon from "public/images/flag.png";
import { useEffect, useRef, useState } from "react";
import {
	FaAngleLeft,
	FaEdit,
	FaFontAwesomeFlag,
	FaInfoCircle,
	FaTrash,
} from "react-icons/fa";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

declare var google: any;

const MissionView: React.FC = () => {
	let history = useHistory();
	const breakpoints = useMediaQuery();
	const { id } = useParams<{ id: string | undefined }>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [mission, setMission] = useState<Mission>({
		typeCode: DATA_COLLECT.value,
	} as Mission);
	const [drawingProps, setDrawingProps] = useState<DrawingProps | null>(null);
	const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
		lat: 42.59844067953348,
		lng: 23.04053410395984,
	});
	const [mapZoomLevel, setMapZoomLevel] = useState<number>(18);
	const areaRefs = useRef<{ area: Area; polygon: any }[]>([]);
	const [focusedArea, setFocusedArea] = useState<Area | null>(null);
	const [focusedBase, setFocusedBase] = useState<Point | null>(null);
	const [patrolModal, setPatrolModal] = useState(false);
	const [saveError, setSaveError] = useState<string | null>(null);

	const api = useApi();

	useEffect(() => {
		if (id) {
			api.get(`missions/${id}`)
				.then(({ data }) => {
					if (data) {
						data.areas!.forEach((area: Area) => {
							if (area.principalHeading === undefined || !area.hasOwnProperty('principalHeading')) {
								// set default value for principal heading
								area.principalHeading = 0;
							}
						});
						setMission(data);
						if (data.droneBases && data.droneBases.length > 0) {
							setMapCenter(data.droneBases[0]);
						}
					}
					setLoading(false);
				})
				.catch((e) => {
					setError(e.toString());
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		if (saveError) {
			setSaveError(null);
		}
	}, [mission]);

	const setAreaPath = (points: Point[], editedArea: Area) => {
		let newArea: Area = { ...editedArea, points };
		let newAreas = mission.areas!.map((currentArea) => {
			return currentArea === editedArea ? newArea : currentArea;
		});
		areaRefs.current.forEach((ref) => {
			if (ref.area === editedArea) {
				ref.area = newArea;
			}
		});
		if (editedArea === focusedArea && newArea) {
			setFocusedArea(newArea);
		}
		setMission({ ...mission, areas: newAreas });
	};

	const updateArea = (newArea: Area, editedArea: Area) => {
		let newAreas = mission.areas!.map((currentArea) => {
			return currentArea === editedArea ? newArea : currentArea;
		});
		areaRefs.current.forEach((ref) => {
			if (ref.area === editedArea) {
				ref.area = newArea;
			}
		});
		setFocusedArea(newArea);
		setMission({ ...mission, areas: newAreas });
	};

	const drawArea = (kind: number) => {
		setFocusedArea(null);
		setFocusedBase(null);
		setDrawingProps({
			mode: google.maps.drawing.OverlayType.POLYGON,
			color: getAreaColor(kind),
			addArea: (area: Area) => {
				setDrawingProps(null);
				area.kind = kind;
				setMission((prevMission) => ({
					...prevMission,
					areas: prevMission.areas
						? [...prevMission.areas, area]
						: [area],
				}));
				setFocusedArea(area);
			},
		});
	};

	const drawBase = () => {
		setFocusedArea(null);
		setFocusedBase(null);
		setDrawingProps({
			mode: google.maps.drawing.OverlayType.MARKER,
			icon: droneBaseIcon,
			addBase: (at: Point) => {
				setDrawingProps(null);
				setMission((prevMission) => {
					return {
						...prevMission,
						droneBases: prevMission.droneBases
							? [...prevMission.droneBases, at]
							: [at],
					};
				});
			},
		});
	};

	const verifySave = () => {
		let failedArea = mission.areas.find(
			(area) =>
				(area.minAltitude !== 0 || area.targetAltitude !== 0 || area.maxAltitude !== 0) &&
				(area.minAltitude >= area.targetAltitude || area.targetAltitude >= area.maxAltitude)
		);
		if (failedArea) {
			setSaveError(viewConstants.saveRestrictions.areaAltitudesOrder);
			setFocusedArea(failedArea);
			return false;
		} else {
			let missionArea = getMissionArea();
			if (missionArea!.maxAltitude - missionArea!.minAltitude < 5.0) {
				setSaveError(
					viewConstants.saveRestrictions.missionAreaRestriction
				);
				setFocusedArea(missionArea);
				return false;
			} else {
				failedArea = mission.areas.find(
					(area) =>
						(area.kind === AreaTypes.INTEREST.num ||
							area.kind === AreaTypes.INTEREST.str) &&
						(area.targetAltitude < missionArea!.minAltitude ||
							area.targetAltitude > missionArea!.maxAltitude)
				);
				if (failedArea) {
					setSaveError(
						viewConstants.saveRestrictions
							.interestAreaAltWithinMissionAreaAlt
					);
					setFocusedArea(failedArea);
					return false;
				}
			}
		}
		return true;
	};

	const save = () => {
		if (verifySave()) {
			if (mission.id) {
				setLoading(true);
				let patrolConfigId: string | null = null;
				if (
					mission.typeCode !== PATROL.value &&
					mission.patrolConfigId
				) {
					patrolConfigId = mission.patrolConfigId;
				}
				api.patch(
					`missions/${mission.id}`,
					patrolConfigId
						? {
								...mission,
								patrolConfigId: null,
								patrolConfig: null,
						  }
						: mission
				)
					.then(({ data }) => {
						if (patrolConfigId) {
							api.delete(`patrolconfig/${patrolConfigId}`);
						}
						setLoading(false);
						setMission(data);
					})
					.catch((e) => {
						setLoading(false);
						console.log(e);
					});
			} else {
				api.post("missions", mission)
					.then((result) => {
						history.push(`/mission/${result.data.id}`);
					})
					.catch((e) => {
						setLoading(false);
						console.log(e);
					});
			}
		}
	};

	const onMapClick = (e: any) => {
		let clickedAreas = areaRefs.current.filter((ref) => {
			return google.maps.geometry.poly.containsLocation(
				e.latLng,
				ref.polygon
			);
		});
		if (clickedAreas.length === 1) {
			setFocusedArea(clickedAreas[0].area);
		} else if (clickedAreas.length > 1) {
			if (focusedArea) {
				let foundIndex = -1;
				for (let i = 0; i < clickedAreas.length - 1; i++) {
					if (focusedArea === clickedAreas[i].area) {
						foundIndex = i;
						break;
					}
				}
				if (foundIndex > -1) {
					foundIndex++;
				} else {
					foundIndex = 0;
				}
				setFocusedArea(clickedAreas[foundIndex].area);
			} else {
				setFocusedArea(clickedAreas[0].area);
			}
		} else {
			setFocusedArea(null);
		}
		if (focusedBase) {
			setFocusedBase(null);
		}
	};

	const deleteCommand = () => {
		if (focusedArea) {
			setMission({
				...mission,
				areas: mission.areas.filter((area) => {
					return area !== focusedArea;
				}),
			});
			setFocusedArea(null);
		}
		if (focusedBase) {
			setMission({
				...mission,
				droneBases: mission.droneBases.filter((base) => {
					return base !== focusedBase;
				}),
			});
			setFocusedBase(null);
		}
	};

	const onTypeChanged = (type: MissionType | null) => {
		setMission({
			...mission,
			typeCode: type ? type.value : 0,
		});
		if (type && type.value === PATROL.value) {
			setPatrolModal(true);
		}
	};

	const handleOpenModal = () => {
		if (mission.typeCode === PATROL.value) {
			setPatrolModal(true);
		}
	};

	const handleCloseModal = () => {
		setPatrolModal(false);
	};

	const getMissionArea = () => {
		if (mission.areas) {
			let result = mission.areas.find(
				(area) =>
					area.kind === AreaTypes.MISSION.num ||
					area.kind === AreaTypes.MISSION.str
			);
			return result ? result : null;
		}
		return null;
	};

	let missionArea: Area | null = getMissionArea();

	let saveDisabled =
		!missionArea || !mission.droneBases || mission.droneBases.length === 0;

	return (
		<>
			{loading ? (
				<Spinner mrgTop="10vh"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				<div className="container box has-text-centered">
					<PatrolModal
						isActive={patrolModal}
						mission={mission}
						setMission={setMission}
						closeModal={handleCloseModal}
					></PatrolModal>
					<div style={{ textAlign: "start" }}>
						<button
							onClick={() => {
								history.push(`/missions`);
							}}
							className="button is-info is-inverted"
						>
							<FaAngleLeft></FaAngleLeft>Back
						</button>
					</div>
					<div
						className={`is-flex  ${
							breakpoints.md
								? "is-flex-direction-column"
								: "is-flex-direction-row"
						}`}
					>
						<div
							className={`p-5   ${
								breakpoints.md
									? " is-flex is-justify-content-space-around is-flex-wrap-wrap"
									: ""
							}`}
						>
							<div
								style={{ minWidth: "266px" }}
								className="field"
							>
								<label className="label">Mission Name: </label>
									<input
									className="input "
									type="text"
									value={mission.name ? mission.name : ""}
									placeholder="Mission Name"
									onChange={(event) => {
										setMission({
											...mission,
											name: event.target.value,
										});
									}}
								></input>
							</div>
							<div
								style={{ minWidth: "266px" }}
								className="field"
							>
								<label className="label">Mission Type: </label>
								<div className="is-flex is-justify-content-center">
									<div
										style={{
											width: "100%",
											marginRight: "8px",
										}}
									>
										<Select
											placeholder={"Choose Mission Type"}
											getOptionLabel={(
												type: MissionType
											) => type.name}
											name="typeSelect"
											value={getMissionType(
												mission.typeCode
											)}
											onChange={onTypeChanged}
											options={[
												DATA_COLLECT,
												PATROL,
												SOLAR_PARK_MAP,
											]}
										/>
									</div>
									<div
										onClick={handleOpenModal}
										style={{ cursor: "pointer" }}
									>
										{mission.typeCode === PATROL.value ? (
											<FaEdit fontSize={36}></FaEdit>
										) : (
											<div
												data-for="collect-type-info"
												data-tip=""
												className="demonstration"
											>
												<a
													href="/#"
													data-iscapture="true"
												>
													<FaInfoCircle
														fontSize={36}
													></FaInfoCircle>
												</a>
												<ReactTooltip
													id="collect-type-info"
													place="top"
													effect="float"
													multiline={true}
													type="info"
												>
													<div className="notification is-info is-light">
														<ul
															style={{
																textAlign:
																	"start",
																listStyle:
																	"initial",
															}}
														>
															{viewConstants.dataCollectTooltop.map(
																(
																	content,
																	index
																) => {
																	return (
																		<li
																			key={
																				index
																			}
																		>
																			{
																				content
																			}
																		</li>
																	);
																}
															)}
														</ul>
													</div>
												</ReactTooltip>
											</div>
										)}
									</div>
								</div>
							</div>
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								{focusedArea ? (
									<AreaAltitudeComponent
										targetArea={focusedArea}
										updateArea={updateArea}
									></AreaAltitudeComponent>
								) : (
									missionArea && (
										<AreaAltitudeComponent
											targetArea={missionArea}
											updateArea={updateArea}
										></AreaAltitudeComponent>
									)
								)}
							</div>
							{saveError && (
								<p className="help is-danger">{saveError}</p>
							)}
						</div>

						<div
							style={{ width: "100%" }}
							className="p-5 container is-flex is-flex-direction-column "
						>
							<div className="is-flex is-justify-content-space-between mb-1 is-flex-wrap-wrap">
								<div className="is-flex is-flex-direction-column is-justify-content-space-around ">
									<h5 className="has-text-centered has-text-weight-semibold">
										Drawing tools
									</h5>
									<div className="is-flex is-flex-direction-row is-justify-content-center">
										<div>
											<button
												data-for="mission-area"
												data-tip=""
												style={{
													borderColor: "#ffcb00",
													color: "ffcb00",
												}}
												className="button is-rounded is-warning is-outlined mr-2"
												onClick={() => {
													if (
														mission.areas &&
														mission.areas.every(
															(area) =>
																area.kind !== AreaTypes.MISSION.str &&
																area.kind !== AreaTypes.MISSION.num
														)
													) {
														setMission({
															...mission,
															areas: mission.areas.filter(
																(area) => {
																	return (
																		area.kind !== AreaTypes.MISSION.str &&
																		area.kind !== AreaTypes.MISSION.num
																	);
																}
															),
														});
													}
													drawArea(AreaTypes.MISSION.num);
												}}
											>
												{" "}
												Mission Area
											</button>
											<ReactTooltip
												id="mission-area"
												place="right"
												effect="float"
												multiline={true}
												type="warning"
											>
												{
													viewConstants.missionArea
														.tooltip
												}
											</ReactTooltip>
										</div>
										<div>
											<button
												data-for="interest-area"
												data-tip=""
												className="button is-rounded is-outlined is-success mr-2"
												onClick={() => drawArea(AreaTypes.INTEREST.num)}
											>
												{" "}
												Interest Area
											</button>
											<ReactTooltip
												id="interest-area"
												place="right"
												effect="float"
												multiline={true}
												type="success"
											>
												{
													viewConstants.interestArea
														.tooltip
												}
											</ReactTooltip>
										</div>
										<div>
											{" "}
											<button
												className="button is-outlined is-rounded is-danger "
												onClick={() => drawArea(AreaTypes.AVOID.num)}
												data-for="avoid-area"
												data-tip=""
											>
												{" "}
												Avoid Area
											</button>
											<ReactTooltip
												id="avoid-area"
												place="top"
												effect="float"
												multiline={true}
												type="error"
											>
												{
													viewConstants.avoidArea
														.tooltip
												}
											</ReactTooltip>
										</div>
									</div>
								</div>
								<div
									className={`is-flex ${
										breakpoints.sm
											? "is-flex-direction-column "
											: "is-flex-direction-row "
									} is-align-items-flex-end`}
								>
									<div>
										<button
											data-for="add-base"
											data-tip=""
											onClick={drawBase}
											className={`button is-outlined is-rounded is-primary ${
												breakpoints.sm ? "mb-2" : ""
											}`}
										>
											Add Base{" "}
											<FaFontAwesomeFlag className="ml-2"></FaFontAwesomeFlag>
										</button>
										<ReactTooltip
											id="add-base"
											place="top"
											effect="float"
											multiline={true}
											type="light"
										>
											{viewConstants.droneBase.tooltip}
										</ReactTooltip>
									</div>
									<button
										onClick={deleteCommand}
										className=" ml-2 button is-outlined is-danger is-rounded"
										disabled={
											focusedArea === null &&
											focusedBase === null
										}
									>
										Delete{" "}
										<FaTrash className="ml-2"></FaTrash>
									</button>
								</div>
							</div>
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<MapContainer>
									<MapView
										mapCenter={mapCenter}
										setMapCenter={setMapCenter}
										mapZoomLevel={mapZoomLevel}
										setMapZoomLevel={setMapZoomLevel}
										onClick={onMapClick}
										addUserLocation={true}
										mapTilt={0}
									>
										{drawingProps && (
											<DrawingTool
												{...drawingProps}
											></DrawingTool>
										)}
										{mission.areas &&
											mission.areas.map((area) => {
												return (
													<AreaView
														key={generateAreaID(
															area
														)}
														area={area}
														color={getAreaColor(
															area.kind
														)}
														setPath={setAreaPath}
														addRef={(
															area: Area,
															polygon: any
														) => {
															areaRefs.current.push(
																{
																	area,
																	polygon,
																}
															);
														}}
														removeRef={(
															polygon: any
														) => {
															for (
																let i = 0;
																i <
																areaRefs.current
																	.length;
																i++
															) {
																if (
																	areaRefs
																		.current[
																		i
																	]
																		.polygon ===
																	polygon
																) {
																	areaRefs.current.splice(
																		i,
																		1
																	);
																	break;
																}
															}
														}}
														focused={
															focusedArea === area
														}
														onClick={
															focusedArea === area
																? onMapClick
																: undefined
														}
													></AreaView>
												);
											})}
										{mission.droneBases &&
											mission.droneBases.map((base) => {
												return (
													<BaseView
														key={`base${base.lat}${
															base.lng
														}${
															focusedBase === base
														}`}
														icon={
															focusedBase === base
																? focusedDroneBaseIcon
																: droneBaseIcon
														}
														position={base}
														onClick={() => {
															if (focusedArea) {
																setFocusedArea(
																	null
																);
															}
															setFocusedBase(
																base
															);
														}}
														focused={
															focusedBase === base
														}
														setPath={(
															at: Point
														) => {
															setFocusedBase(at);
															setMission({
																...mission,
																droneBases:
																	mission.droneBases.map(
																		(b) => {
																			return b ===
																				base
																				? at
																				: b;
																		}
																	),
															});
														}}
													></BaseView>
												);
											})}
									</MapView>
								</MapContainer>
							</div>
						</div>
					</div>
					<div className="">
						<div data-for="save-button-disabled" data-tip="">
							<button
								className="button is-outlined is-rounded is-info mt-2"
								onClick={save}
								disabled={saveDisabled}
							>
								Save Mission
							</button>
						</div>
						{saveDisabled && (
							<ReactTooltip
								id="save-button-disabled"
								place="top"
								effect="float"
								multiline={true}
								type="error"
							>
								{viewConstants.saveButtonDisabledTooltip}
							</ReactTooltip>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default MissionView;
