import { useEffect, useRef, useState } from "react";
import Select, { Props } from "react-select";

const AutoSizedSelect: React.FC<Props<any>> = ({ options, ...rest }) => {
	const [menuWidth, setMenuWidth] = useState(0);
	const menuWidthRef = useRef(0);

	menuWidthRef.current = menuWidth;

	const selectRef = useRef<any | null | undefined>();
	useEffect(() => {
		if (options && options?.length > 0) {
			menuWidthRef.current = 0;
			selectRef.current.focus();
		}
	}, [options?.length]);

  const onFocus = () => {
		if (!menuWidthRef.current) {
			setTimeout(() => {
				const menu = selectRef?.current?.select.menuListRef;
				const width = menu && menu.getBoundingClientRect().width;
				if (width) {
					setMenuWidth(width);
				}
				selectRef.current.blur();
			}, 0);
		}
	};

	const styles = {
		control: (css: any) => {
			return {
				...css,
				width: menuWidthRef.current || "auto",
				opacity: menuWidthRef.current ? 1 : 0,
			};
		},
		menu: ({ width, ...css }: any) => {
			return {
				...css,
				opacity: menuWidthRef.current ? 1 : 0,
			};
		},
		// Add padding to account for width of Indicators Container plus padding
		option: (css: any) => ({ ...css, paddingRight: 36 + 8 }),
	};
	return (
		<Select
			ref={selectRef}
			styles={styles}
			onFocus={onFocus}
			openMenuOnFocus={true}
      options={options}
			{...rest}
		/>
	);
};

export default AutoSizedSelect;
