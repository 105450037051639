import { Deck } from "components/style/Deck";
import { Tenant } from "dtos/Tenant";
import TenantCard from "./TenantCard";

interface Props {
    tenants: Tenant[];
    onTenantClicked: (tenant: Tenant) => void;
}

const TenantDeck: React.FC<Props> = ({ tenants, onTenantClicked }) => {
	return (
		<Deck>
			{tenants.map((tenant) => (
				<TenantCard key={tenant.id} tenant={tenant} onClick={onTenantClicked}></TenantCard>
			))}
		</Deck>
	);
};

export default TenantDeck;
