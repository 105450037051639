import AssignmentRow from "components/AssignmentRow";
import Spinner from "components/Spinner";
import useMount from "customhooks/useMount";
import { Mission } from "dtos";
import { Assignment } from "dtos/Assignment";
import { useEffect, useState } from "react";
import AutoSizedSelect from "components/AutoSizedSelect";
import { useParams } from "react-router";
import { useApi, UserModel } from "api/API";
import { useRecoilValue } from "recoil";
import { userAtom } from "myrecoil/UserState";
import { UserRoles } from "helpers/UserUtils";
import { AssignmentStatus, assingmentsDateComparator } from "helpers/AssignmentUtils";

const AssignmentsView = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [assignments, setAssignments] = useState<Assignment[]>([]);
	const [allMissions, setAllMissions] = useState<Mission[]>([]);
	const [selectedMission, setSelectedMission] = useState<Mission>();
	const { selectedMissionParam } =
		useParams<{ selectedMissionParam: string | undefined }>();

	const api = useApi();

	const currentUser = useRecoilValue(userAtom);

	useMount(() => {
		api.get("missions")
			.then(({ data }) => {
				let missions = data;
				if (missions) {
					setAllMissions(missions);
					if (selectedMissionParam) {
						setSelectedMission(
							missions.find(
								(mission: Mission) =>
									mission.id === selectedMissionParam
							)
						);
					} else if (missions.length > 0) {
						setSelectedMission(missions[0]);
					}
				}
				setError(null);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setError(error.toString());
			});
	});
	useEffect(() => {
		if (selectedMission) {
			setLoading(true);
			setError(null);
			api.get(`assignments/mission/${selectedMission.id}`)
				.then(({ data }) => {
					let assignments = data.sort(assingmentsDateComparator);
					setAssignments(assignments);
					setError(null);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.toString());
				});
		}
	}, [selectedMission, setSelectedMission]);

	const handleMissionChanged = (mission: Mission | null) => {
		if (mission && mission.id !== selectedMission?.id) {
			setAssignments([]);
			setSelectedMission(mission);
		}
	};

	const setAssignmentStatus = (assignment: Assignment, newStatus: string) => {
		api.patch(`assignments/${assignment.id}`, {
			...assignment,
			state: newStatus,
		}).then(({ data }) => {
			if (data && data.id) {
				setAssignments(
					assignments.map((assignment) => {
						return assignment.id === data.id ? data : assignment;
					})
				);
			}
		});
	};

	return (
		<div className="container ">
			{allMissions.length > 0 ? (
				<AutoSizedSelect
					placeholder={"Select a Solar Park"}
					getOptionLabel={(mission: Mission) => mission.name}
					getOptionValue={(mission: Mission) => mission.id}
					name="typeSelect"
					value={selectedMission}
					onChange={handleMissionChanged}
					options={allMissions}
				/>
			) : null}
			<div
				className={`Rtable header Rtable--${
					currentUser!.user_role === UserRoles.VIEWER ? 3 : 4
				}cols`}
			>
				<div className="Rtable-cell">Solar Park</div>
				{currentUser!.user_role !== UserRoles.VIEWER && (
					<div className="Rtable-cell">Status</div>
				)}
				<div className="Rtable-cell">Completed</div>
				<div className="Rtable-cell">View</div>
			</div>
			{loading ? (
				<Spinner mrgTop="65px"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : selectedMission ? (
				getAssignmentsRows(
					assignments,
					selectedMission,
					currentUser!,
					setAssignmentStatus
				)
			) : (
				<div style={{ width: "100%", textAlign: "center" }}>
					Please select a Solar Park from the dropdown menu.
				</div>
			)}
		</div>
	);
};

const getAssignmentsRows = (
	assignments: Assignment[],
	selectedMission: Mission,
	user: UserModel,
	setAssignmentStatus: (assignment: Assignment, newStatus: string) => void
) => {
	if (user.user_role === UserRoles.VIEWER) {
		assignments = assignments.filter(
			(assignment) => assignment.state === AssignmentStatus.APPROVED.value
		);
	}
	return assignments.length > 0 ? (
		assignments.map((assignment: Assignment) => {
			return (
				<AssignmentRow
					key={assignment.id + assignment.state}
					assignment={assignment}
					mission={selectedMission}
					setAssignmentStatus={setAssignmentStatus}
					showStatus={user.user_role !== UserRoles.VIEWER}
				/>
			);
		})
	) : (
		<div style={{ width: "100%", textAlign: "center" }}>
			No inspections.
		</div>
	);
};

export default AssignmentsView;
