import Box from "components/Box";
import { useApi } from "api/API"
import { StatusReport } from "dtos";
import {
  FlightState,
  MissionState,
  NavActionType,
  Commands,
} from "helpers/DroneStatusEnums";
import {
  FaArrowDown,
  FaArrowUp,
  FaHome,
  FaPlay,
  FaPowerOff,
  FaRedo,
  FaStop,
} from "react-icons/fa";

interface Props {
  statusReport: StatusReport;
  droneId: string;
}

const DroneCommands: React.FC<Props> = ({ statusReport, droneId }) => {
  const api = useApi();
  
  const sendCommand = (type: number) => {
    api.post(`drones/${droneId}/dronecommands`, { action: type });
  };
  return (
    <Box>
      {isArmVisible(statusReport) && (
        <button
          onClick={() => {
            sendCommand(Commands.ARM);
          }}
          className="button is-success is-light is-outlined"
        >
          <span>Arm</span>
          <FaPlay style={{ marginLeft: "5px" }}></FaPlay>
        </button>
      )}
      {isDisarmVisible(statusReport) && (
        <button
          className="button is-danger is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.DISARM);
          }}
        >
          <span>Disarm</span>
          <FaStop style={{ marginLeft: "5px" }}></FaStop>
        </button>
      )}
      {isTakeoffVisible(statusReport) && (
        <button
          className="button is-success is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.TAKEOFF);
          }}
        >
          <span>Takeoff</span>
          <FaArrowUp style={{ marginLeft: "5px" }}></FaArrowUp>
        </button>
      )}
      {isLandVisible(statusReport) && (
        <button
          className="button is-info is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.LAND);
          }}
        >
          <span>Land</span>
          <FaArrowDown style={{ marginLeft: "5px" }}></FaArrowDown>
        </button>
      )}
      {isEmergencyLandVisible(statusReport) && (
        <button
          className="button is-warning is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.EMERGENCY_LAND);
          }}
        >
          <span>Emergency Land</span>
          <FaArrowDown style={{ marginLeft: "5px" }}></FaArrowDown>
        </button>
      )}
      {isEmergencyStopVisible(statusReport) && (
        <button
          className="button is-danger is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.EMERGENCY_STOP);
          }}
        >
          <span>Emergency Stop</span>
          <FaStop style={{ marginLeft: "5px" }}></FaStop>
        </button>
      )}
      {isStartMissionVisible(statusReport) && (
        <button
          className="button is-success is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.START_MISSION);
          }}
        >
          <span>Start Mission</span>
          <FaPlay style={{ marginLeft: "5px" }}></FaPlay>
        </button>
      )}
      {isReturnHomeVisible(statusReport) && (
        <button
          className="button is-link is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.RETURN_HOME);
          }}
        >
          <span>Return Home</span>
          <FaHome style={{ marginLeft: "5px" }}></FaHome>
        </button>
      )}
      {isSysCmdVisible(statusReport) && (
        <button
          className="button is-danger is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.REBOOT);
          }}
        >
          <span>Reboot</span>
          <FaRedo style={{ marginLeft: "5px" }}></FaRedo>
        </button>
      )}
      {isSysCmdVisible(statusReport) && (
        <button
          className="button is-danger is-light is-outlined"
          onClick={() => {
            sendCommand(Commands.SHUT_DOWN);
          }}
        >
          <span>Shut Down</span>
          <FaPowerOff style={{ marginLeft: "5px" }}></FaPowerOff>
        </button>
      )}
    </Box>
  );
};

const isArmVisible = (statusReport: StatusReport) => {
  return !statusReport.armed && !statusReport.disabled;
};

const isDisarmVisible = (statusReport: StatusReport) => {
  return statusReport.armed && isLanded(statusReport);
};

const isStartMissionVisible = (statusReport: StatusReport) => {
  // when not disabled
  // and flight state is landed, unknown, hovering, or navigating
  // and mission status is pending or aborted
  return (
    !statusReport.disabled &&
    !isLanding(statusReport) &&
    !isTakingOff(statusReport) &&
    [
      FlightState.FLIGHT_STATE_LANDED,
      FlightState.FLIGHT_STATE_HOVERING,
      FlightState.FLIGHT_STATE_NAVIGATING,
      FlightState.FLIGHT_STATE_UNKNOWN,
    ].includes(statusReport.droneStateType) &&
    [
      MissionState.MISSION_STATUS_ABORTED,
      MissionState.MISSION_STATUS_PENDING,
      MissionState.MISSION_STATUS_COMPLETE,
    ].includes(statusReport.missionStateType)
  );
};

const isTakeoffVisible = (statusReport: StatusReport) => {
  // when armed and not disabled
  // and flight state is landed or unknown
  // and action is completed landing
  return statusReport.armed && !statusReport.disabled && isLanded(statusReport);
};
const isLandVisible = (statusReport: StatusReport) => {
  // when armed
  // and flight state is not landed or landing
  // and action is complete or it is not a land action
  return (
    statusReport.armed && !isLanded(statusReport) && !isLanding(statusReport)
  );
};

const isEmergencyLandVisible = (statusReport: StatusReport) => {
  // when armed and not landed
  return statusReport.armed && !isLanded(statusReport);
};

const isEmergencyStopVisible = (statusReport: StatusReport) => {
  return isEmergencyLandVisible(statusReport);
};

const isReturnHomeVisible = (statusReport: StatusReport) => {
  // when armed
  // and flight state is hovering or navigating
  // and action type is hold
  return (
    statusReport.armed &&
    !isLanded(statusReport) &&
    !isLanding(statusReport) &&
    !isReturning(statusReport) &&
    !isTakingOff(statusReport)
  );
};

const isSysCmdVisible = (statusReport: StatusReport) => {
  // when not armed and (landed or unknown)
  return !statusReport && (isUnknown(statusReport) || isLanded(statusReport));
};

const isUnknown = (statusReport: StatusReport) => {
  return [FlightState.FLIGHT_STATE_UNKNOWN].includes(
    statusReport.droneStateType
  );
};

const isLanded = (statusReport: StatusReport) => {
  return (
    [FlightState.FLIGHT_STATE_LANDED].includes(statusReport.droneStateType) ||
    ([FlightState.FLIGHT_STATE_UNKNOWN].includes(statusReport.droneStateType) &&
      statusReport.droneActionComplete &&
      [NavActionType.NAV_ACTION_LAND].includes(statusReport.droneActionType))
  );
};

const isLanding = (statusReport: StatusReport) => {
  return (
    !statusReport.droneActionComplete &&
    [
      NavActionType.NAV_ACTION_LAND,
      NavActionType.NAV_ACTION_LAND_APPROACH,
    ].includes(statusReport.droneActionType)
  );
};

const isTakingOff = (statusReport: StatusReport) => {
  return (
    !statusReport.droneActionComplete &&
    [NavActionType.NAV_ACTION_TAKEOFF].includes(statusReport.droneActionType)
  );
};

const isReturning = (statusReport: StatusReport) => {
  return (
    !statusReport.droneActionComplete &&
    [
      NavActionType.NAV_ACTION_LAND,
      NavActionType.NAV_ACTION_LAND_APPROACH,
      NavActionType.NAV_ACTION_RUNWAY_APPROACH,
      NavActionType.NAV_ACTION_RTB,
    ].includes(statusReport.droneActionType)
  );
};

export default DroneCommands;
