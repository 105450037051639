import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import MapContainer from "components/map/MapContainer";
import MapView from "components/map/MapView";
import { Mission, Point } from "dtos";
import { useEffect, useState } from "react";
import droneBaseIcon from "public/images/flag.png";
import droneIcon from "public/images/drone.png";
import { getAreaColor, generateAreaID } from "helpers/MissionAreaUtils";
import Box from "components/Box";

interface Props {
  mission?: Mission;
  droneLocation?: Point;
  plannedRoute?: Point[];
}

const DroneStatusMap: React.FC<Props> = ({
  mission,
  droneLocation,
  plannedRoute,
}) => {
  const decideInitialMapCenter = () => {
    if (droneLocation) {
      return droneLocation;
    }
    if (mission && mission.droneBases && mission.droneBases.length > 0) {
      return mission.droneBases[0];
    }
    return {
      lat: 42.59844067953348,
      lng: 23.04053410395984,
    };
  };
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(
    decideInitialMapCenter()
  );

  useEffect(() => {
    setMapCenter(decideInitialMapCenter);
  }, [droneLocation]);

  const [mapZoomLevel, setMapZoomLevel] = useState<number>(18);
  return (
    <Box>
      <MapContainer>
        <MapView
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
          mapZoomLevel={mapZoomLevel}
          setMapZoomLevel={setMapZoomLevel}
          mapTilt={0}
        >
          {mission?.areas &&
            mission.areas.map((area) => {
              return (
                <Polygon
                  key={generateAreaID(area)}
                  path={area.points}
                  options={{
                    fillColor: getAreaColor(area.kind),
                    strokeColor: getAreaColor(area.kind),
                  }}
                ></Polygon>
              );
            })}
          {mission?.droneBases &&
            mission.droneBases.map((base) => {
              return (
                <Marker
                  key={`base${base.lat}${base.lng}`}
                  position={base}
                  icon={droneBaseIcon}
                ></Marker>
              );
            })}
          {droneLocation && (
            <Marker position={droneLocation} icon={droneIcon}></Marker>
          )}
          {plannedRoute && plannedRoute.length > 0 && (
            <Polyline
              path={plannedRoute}
              options={{
                strokeColor: "red",
              }}
            ></Polyline>
          )}
        </MapView>
      </MapContainer>
    </Box>
  );
};

export default DroneStatusMap;
