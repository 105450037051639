export const getMissionType = (value: number | string) => {
	switch (value) {
		case DATA_COLLECT.value:
		case DATA_COLLECT.strValue:
			return DATA_COLLECT;
		case PATROL.value:
		case PATROL.strValue:
			return PATROL;
		case SOLAR_PARK_MAP.value:
		case SOLAR_PARK_MAP.strValue:
			return SOLAR_PARK_MAP;
		default:
			return null;
	}
};

export const DATA_COLLECT = {
	value: 2,
	name: "Data collect",
	strValue: "DataCollect",
};
export const PATROL = { value: 3, name: "Patrol", strValue: "Patrol" };
export const SOLAR_PARK_MAP = {
	value: 4,
	name: "Solar Park Map",
	strValue: "SolarParkMap",
};

export interface MissionType {
	value: number;
	name: string;
}
