import { Tenant } from "dtos/Tenant";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const theTenant = atom<Tenant | null>({
	key: "tenant.theTenant",
	default: null,
	effects_UNSTABLE: [persistAtom],
});

export const enableTenantSwitch = atom<boolean>({
	key: "tenant.enableTenantSwitch",
	default: true,
	effects_UNSTABLE: [persistAtom],
});
