import React, { useState, useEffect, createContext } from "react";
import { MediaQueryContextProps } from "./MediaQueryContextProps";

export const MediaQueryContext = createContext<
  MediaQueryContextProps | undefined
>(undefined);

interface Props {
  queries: { [key: string]: string };
}

const MediaQueryProvider: React.FC<Props> = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce(
        (acc: { [key: string]: boolean }, media) => {
          acc[media] = !!(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          );
          return acc;
        },
        {}
      );
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: { [key: string]: boolean } = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <MediaQueryContext.Provider value={queryMatch}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export default MediaQueryProvider;
