import React from "react";
import ko from "knockout";
import "devexpress-reporting/dx-reportdesigner";
import { Constants } from "helpers/Constants";

class ReportDesigner extends React.Component {
	constructor(props) {
        super(props);
        this.designerRef = React.createRef();
		this.reportUrl = ko.observable("BrokenTables");
		this.requestOptions = {
			host: Constants.apiRoot + "/",
			getDesignerModelAction: "ReportDesignerSetup/GetReportDesignerModel",
		};
	}
	render() {
		return <div ref={this.designerRef} data-bind="dxReportDesigner: $data"></div>;
	}
	componentDidMount() {
		ko.applyBindings(
			{
				reportUrl: this.reportUrl,
				requestOptions: this.requestOptions,
			},
			this.designerRef.current
		);
	}
	componentWillUnmount() {
		ko.cleanNode(this.designerRef.current);
	}
}

export default ReportDesigner;
