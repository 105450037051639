import DroneSettingsView from "./DroneSettingsView";
import NavBar from "components/NavBar";
import { Redirect, Route, Switch } from "react-router";
import DronesView from "./DronesView";
import MissionsView from "./MissionsView";
import MissionView from "./MissionView";
import DroneStatusView from "./DroneStatusView";
import AssignmentsView from "./AssignmentsView";
import AssignmentView from "./AssignmentView/AssignmentView";
import TenantGate from "gates/TenantGate";
import UserRoleGate from "gates/UserRoleGate";
import UsersView from "./UsersView";
import ReportPage from "pages/ReportPage";
import AdminPage from "pages/AdminPage";

const UserRoleRoute: React.FC<{
	redirectTo: string;
	roles: string[];
	[x: string]: any;
}> = ({ children, redirectTo, roles, ...rest }) => (
	<Route {...rest}>
		<UserRoleGate
			allowedRoles={roles}
			AlternativeComponents={() => {
				return <Redirect to={redirectTo}></Redirect>;
			}}
		>
			{children}
		</UserRoleGate>
	</Route>
);

const MainView: React.FC = () => {
	return (
		<Switch>
			<UserRoleRoute
				redirectTo="/inspections"
				roles={["admin"]}
				path={`/admin`}
			>
				<AdminPage></AdminPage>
			</UserRoleRoute>
			<TenantGate>
				<section className="hero">
					<div className="hero-head dynamic-theme-header">
						<NavBar></NavBar>
					</div>
					<div className="hero-body dynamic-theme-body ">
						<main className="container is-align-self-flex-start">
							<Switch>
								<Route
									path={`/inspections/:selectedMissionParam?`}
									component={AssignmentsView}
								/>
								<Route
									exact={true}
									path="/inspection/:id?"
									component={AssignmentView}
								/>
								<Route
									path="/inspection/report/view"
									component={ReportPage}
								/>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["operator", "admin", "clientadmin"]}
									path={`/drones`}
								>
									<DronesView></DronesView>
								</UserRoleRoute>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["operator", "admin", "clientadmin"]}
									path={`/missions`}
								>
									<MissionsView></MissionsView>
								</UserRoleRoute>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["operator", "admin", "clientadmin"]}
									path={`/drone/settings/:id?`}
								>
									<DroneSettingsView></DroneSettingsView>
								</UserRoleRoute>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["operator", "admin", "clientadmin"]}
									path={`/drone/status/:id?`}
								>
									<DroneStatusView></DroneStatusView>
								</UserRoleRoute>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["operator", "admin", "clientadmin"]}
									path={`/mission/:id?`}
								>
									<MissionView></MissionView>
								</UserRoleRoute>
								<UserRoleRoute
									redirectTo="/inspections"
									roles={["admin", "clientadmin"]}
									path={`/users`}
								>
									<UsersView></UsersView>
								</UserRoleRoute>
								<Redirect to={`/inspections`} />
							</Switch>
						</main>
					</div>
				</section>
			</TenantGate>
		</Switch>
	);
};

export default MainView;
