import { Assignment } from "dtos";
import { useRef, useState } from "react";
import { FaBook } from "react-icons/fa";
import ReportExportComponent from "../../../../../components/assignment/ReportExportComponent";
import DownloadReportModal from "../../Modals/DownloadReportModal/DownloadReportModal";
import classes from "./ReportActions.module.css";
import useInterval from "customhooks/useInterval";
import { useApi } from "api/API";
import { useReportDownload } from "customhooks/useReportDownload";
import ProgressBar from "components/ProgressBar";
interface Props {
  assignment: Assignment;
}

interface ProgressData {
  processingPage: number;
  pageCount: number;
  state: string;
}

interface Progress {
  data: ProgressData;
  status: number;
  statusText: string;
}

function ReportActions({ assignment }: Props) {
  let reportType = useRef<string>("PVInspectionReport");

  const [fileFormat, setFileFormat] = useState<string>("PDF");
  const [queryParams, setQueryParams] = useState<string>("");
  const [token, setToken] = useState<string | null>();
  const [progress, setProgress] = useState<ProgressData | null>(null);

  const [isActive, setIsActive] = useState<boolean>(false);

  const api = useApi();
  const download = useReportDownload();
  useInterval(() => {
      api
        .get(`reports/exportstatus?token=${token}`)
        .then((pr: Progress) => {
          console.log('Got timed download status', pr);
          if ("NotActive" === pr.data.state) {
            console.log("Report generation stopped unexpectedly!");
            setProgress(null);
          } else {
            if ("Completed" === pr.data.state) {
              if (token)
              {
                console.log("Report generation completed! Starting download...");
                download.download(token);
              }
              setProgress(null);
            } else {
              setProgress(pr.data);
            }
          }
          console.log("Progress", pr.data);
        }).catch(reason => {
          console.log("Error getting export status: ", reason);
        });
    },
    progress != null && progress.state != "NotActive" ? 5000 : null
  );

  const exportTo = (to: string) => {
    const tokenUrl = `reports/${reportType.current}/token?to=${to}&assignmentId=${assignment.id}${queryParams}`;
    api.get(tokenUrl).then(tokenData => {
      console.log("Got token for report generation!", tokenData);
      setToken(tokenData.data);
      const generateUrl = `reports/generate?token=${tokenData.data}`;
      console.log("Requesting report generation!");
      api.get(generateUrl).then((pr: Progress) => {
        console.log('Got initial download status', pr);
        if ("NotActive" === pr.data.state) {
          console.log("Failed to initiate report generation!");
          setProgress(null);
        } else {
          if ("Completed" === pr.data.state) {
            console.log("Report generation completed immediately! Starting download...");
            download.download(tokenData.data);
            setProgress(null);
          } else {
            setProgress(pr.data);
          }
        }
        console.log("Initial Progress", pr.data);
      }).catch(reason => console.log("Failed to initiate report generation!", reason));
      setIsActive(false);
    }).catch(reason => console.log("Failed to get token!", reason));
  };

  const downloadReport = () => {
    exportTo(fileFormat);
  };

  return (
    <div className={classes.download_report_bar}>
      {/* <a
        href={`/inspection/report/view?${reportName.current}=PVInspectionReport&id=${assignment.id}${queryParams}`}
        target="_blank"
        rel="noreferrer noopener"
        className="button is-info is-light is-outlined"
        style={{ marginRight: "5px" }}
      >
        <FaBook style={{ marginRight: "5px" }}></FaBook>View Report
      </a> */}
      {progress != null && progress.state != "NotActive" && (
        <div style={{ width: 50 }}><ProgressBar completed={progress.processingPage} /></div>
      )}
      {(progress == null || progress.state == "NotActive") && (
        <DownloadReportModal
          setQueryParams={setQueryParams}
          setFileFormat={setFileFormat}
          downloadReport={downloadReport}
        ></DownloadReportModal>
      )}

      {/* <ReportExportComponent
        reportName={reportName.current}
        assignmentId={assignment.id}
        generateImgs={false}
        queryParams={queryParams}
      ></ReportExportComponent> */}
    </div>
  );
}

export default ReportActions;
