import { MediaQueryContextProps, MediaQueryContext } from "features/mediaquery";
import { useContext } from "react";

export const useMediaQuery = (): MediaQueryContextProps => {
    const context = useContext<MediaQueryContextProps | undefined>(MediaQueryContext);
    
    if (!context) {
      throw new Error('MediaQueryProvider context is undefined, please verify you are calling useMediaQuery() as child of a <MediaQueryProvider> component.');
    }
    
    return context;
  };