import { Card, CardBody, CardHead } from "components/style/Deck";
import { Tenant } from "dtos/Tenant";

interface Props {
	tenant: Tenant;
	onClick: (tenant: Tenant) => void;
}

const TenantCard: React.FC<Props> = ({ tenant, onClick }) => {
	return (
		<Card>
			<CardHead><p className="subtitle">{tenant.name}</p></CardHead>
			<CardBody>
				<span className="button is-primary" style={{width: "100%"}} onClick={() => onClick(tenant)}>Select</span>
			</CardBody>
		</Card>
	);
};

export default TenantCard;
