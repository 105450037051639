import { Drone } from "dtos";
import { FlightState } from "./DroneStatusEnums";

export const isOnline = (drone: Drone) => {
	return drone.isOnline;
};

export const getDroneState = (type: string) => {
	switch (type) {
		case FlightState.FLIGHT_STATE_LANDED:
			return "Landed";
		case FlightState.FLIGHT_STATE_LANDING:
			return "Landing";
		case FlightState.FLIGHT_STATE_TAKINGOFF:
			return "Takeoff";
		case FlightState.FLIGHT_STATE_HOVERING:
			return "Hover";
		case FlightState.FLIGHT_STATE_NAVIGATING:
			return "Navigating";
		case FlightState.FLIGHT_STATE_EMERGENCY:
			return "Emergency";
		case FlightState.FLIGHT_STATE_MOTOR_RAMPING:
			return "Motors Ramping";
		case FlightState.FLIGHT_STATE_EMERGENCY_LANDING:
			return "Emergency Landing";
		default:
			return "Unknown";
	}
};
