import { Assignment } from "dtos";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router";
import DownloadReportModal from "../../Modals/DownloadReportModal/DownloadReportModal";
import ReportActions from "../ReportActions/ReportActions";

interface Props {
    assignment: Assignment
}

function AssignmentViewHeader({assignment}: Props) {
  let history = useHistory();

  return (
    <div
      style={{
        textAlign: "start",
        marginBottom: "2vh",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <button
        onClick={() => {
          history.push(`/inspections/${assignment.missionId}`);
        }}
        className="button is-info is-inverted"
      >
        <FaAngleLeft></FaAngleLeft>Back
      </button>
      <ReportActions assignment={assignment}></ReportActions>
    </div>
  );
}

export default AssignmentViewHeader;
