import { Marker } from "@react-google-maps/api";
import { Point } from "dtos";

interface Props {
  icon: any;
  position: Point;
  onClick: (e: any) => void;
  focused?: boolean;
  setPath: (at: Point) => void;
}
const BaseView: React.FC<Props> = ({
  icon,
  position,
  onClick,
  focused,
  setPath,
}) => {
  return (
    <Marker
      position={{ lat: position.lat, lng: position.lng }}
      icon={icon}
      draggable={focused}
      options={{ optimized: false }}
      onClick={onClick}
      onDragEnd={(e) => {
        if (e && e.latLng) {
          setPath({ lat: e.latLng.lat(), lng: e.latLng.lng() } as Point)
        }
      }}
    ></Marker>
  );
};
export default BaseView;
