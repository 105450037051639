import AppWithAuth from "AppWithAuth";
import MediaQueryProvider from "features/mediaquery/MediaQueryProvider";
import Footer from "components/Footer";
import { RecoilRoot } from "recoil";

const queries = {
  xs: "(max-width: 320px)",
  sm: "(max-width: 720px)",
  md: "(max-width: 1024px)",
  or: "(orientation: portrait)", // we can check orientation also
};

function App() {
  return (
    <RecoilRoot>
      <MediaQueryProvider queries={queries}>
        <div
          style={{ minHeight: "100vh", position: "relative" }}
          className="is-flex is-flex-direction-column "
        >
          <AppWithAuth></AppWithAuth>
          <Footer></Footer>
        </div>
      </MediaQueryProvider>
    </RecoilRoot>
  );
}

export default App;
