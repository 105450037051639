import { useState } from "react";
import Checkbox, { CheckboxI } from "../Checkbox/Checkbox";
import ListSection from "../ListSection/ListSection";

interface Props {
  options: CheckboxI[];
  title: string;
  forKey: string;
  initialSelectedOption?: string;
  onSelect: (selected: string) => void;
}

function RadioButtons({
  options,
  title,
  forKey,
  onSelect,
  initialSelectedOption,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    initialSelectedOption
  );

  function onChangeHandler(item: CheckboxI) {
    setSelectedOption(item.value);
    onSelect(item.value);
  }

  return (
    <ListSection title={title}>
      <form>
        {options.map((checkbox) => (
          <Checkbox
            key={forKey + checkbox.value}
            checkbox={checkbox}
            onChange={onChangeHandler}
            isRadio={true}
            selectedOption={selectedOption}
          ></Checkbox>
        ))}
      </form>
    </ListSection>
  );
}

export default RadioButtons;
