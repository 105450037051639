import { Area, Point } from "dtos";
import React, { useEffect, useRef } from "react";
import { Polygon, Polyline } from "@react-google-maps/api";
import { AreaTypes } from "helpers/MissionAreaUtils";

interface Props {
  area: Area;
  color: string;
  setPath: (points: Point[], area: Area) => void;
  addRef: (area: Area, polygon: any) => void;
  removeRef: (polygon: any) => void;
  focused?: boolean;
  onClick?: (event: any) => void;
}

const AreaView: React.FC<Props> = ({
  area,
  color,
  setPath,
  addRef,
  focused,
  removeRef,
  onClick,
}) => {
  const polygonRef = useRef<any>(null);

  const onLoad = (polygon: any) => {
    polygonRef.current = polygon;
    addRef(area, polygon);
  };

  const onEdit = () => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath, area);
    }
  };

  useEffect(() => {
    return () => {
      removeRef(polygonRef.current);
    };
  }, []);

  const deg2rad = (valueDeg: number) => {
    return valueDeg * Math.PI / 180.0;
  };
  const rad2deg = (valueRad: number) => {
    return valueRad * 180.0 / Math.PI;
  };

  const distance = (pt1: Point, pt2: Point) => {
    const earthRadiusM = 6378137.0; // use WSG84
    const dLat = deg2rad(pt2.lat - pt1.lat);
    const dLon = deg2rad(pt2.lng - pt1.lng);
    const lat1 = deg2rad(pt1.lat);
    const lat2 = deg2rad(pt2.lat);
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadiusM * c;
  };

  const movePoint = (pt: Point, distLngM: number, distLatM: number) => {
    const earthRadiusM = 6378137.0; // use WSG84
    return {
      lat: pt.lat + rad2deg(distLatM / earthRadiusM),
      lng: pt.lng + rad2deg(distLngM / earthRadiusM)
    } as Point;
  };

  const getPrincipalAxisEndpoints = () => {
    let endpoints: Point[] = [];
    let minLat = area.points[0].lat;
    let maxLat = area.points[0].lat;
    let minLng = area.points[0].lng;
    let maxLng = area.points[0].lng;
    area.points.forEach((point) => {
      minLat = Math.min(point.lat, minLat);
      maxLat = Math.max(point.lat, maxLat);
      minLng = Math.min(point.lng, minLng);
      maxLng = Math.max(point.lng, maxLng);
    });
    const centroid = {
      lng: 0.5 * (minLng + maxLng),
      lat: 0.5 * (minLat + maxLat)
    } as Point;
    const principalHeadingRad =
      Math.atan2(Math.sin(deg2rad(area.principalHeading)),
                 Math.cos(deg2rad(area.principalHeading)));
    const distM = distance({lng: maxLng, lat: maxLat} as Point, {lng:minLng, lat: minLat} as Point);
    const distLngM = 0.65 * distM * Math.cos(principalHeadingRad);
    const distLatM = 0.65 * distM * Math.sin(principalHeadingRad);
    const start_point = movePoint(centroid, distLngM, distLatM);
    const end_point = movePoint(centroid, -distLngM, -distLatM);
    endpoints.push(start_point);
    endpoints.push(end_point);
    return endpoints;
  };

  const dashedLineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  return (
    <>
      <Polygon
        editable={focused}
        draggable={focused}
        path={area.points}
        onLoad={onLoad}
        options={{
          fillColor: color,
          strokeColor: color,
          clickable: focused,
        }}
        onClick={onClick}
        onMouseUp={focused ? onEdit : undefined}
        onDragEnd={focused ? onEdit : undefined}
      />
      { focused && (area.kind === AreaTypes.INTEREST.num || area.kind === AreaTypes.INTEREST.str) && area.points.length > 2 &&
        <Polyline
          editable={false}
          draggable={false}
          path={getPrincipalAxisEndpoints()}
          options={{
            strokeOpacity: 0,
            strokeColor: color,
            clickable: false,
            icons: [
              {
                icon: dashedLineSymbol,
                offset: "0",
                repeat: "20px",
              }
            ]
          }}
        />}
    </>
  );
};

export default AreaView;
