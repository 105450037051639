import { Tenant, User } from "dtos";
import { Constants } from "helpers/Constants";
import { theTenant } from "myrecoil/TenantState";
import { useRecoilValue } from "recoil";
import { useApiBase } from "./API";

export const useTenantsApi = () => {
	const apiBase = useApiBase(
		`${Constants.apiRoot}/api/${Constants.apiVersion}/`
	);
	const currentTenant = useRecoilValue(theTenant);

	return {
		getAllTenants: () => {
			return apiBase
				.get("tenants?filter[isactive]=true")
				.then(({ data }) => {
					if (Array.isArray(data)) {
						return data;
					}
					return [];
				});
		},
		joinUser: (user: User, tenantId: string | null) => {
			return apiBase.patch(
				`tenants/${tenantId ? tenantId : currentTenant!.id}/joinUser/${
					user.id
				}`,
				user
			);
		},
		unlinkUser: (user: User) => {
			return apiBase.delete(
				`tenants/${currentTenant!.id}/unlinkUser/${user.id}`
			);
		},
		getTenantUsers: (tenantId: string) => {
			return apiBase.get(`tenants/${tenantId}/users`);
		},
		saveTenant: (tenant: Tenant) => {
			if (tenant.id) {
				return apiBase.patch(`tenants/${tenant.id}`, tenant);
			} else {
				return apiBase.post(`tenants`, tenant);
			}
		},
		deleteTenant: (tenant: Tenant) => {
			return apiBase.patch(`tenants/${tenant.id}`, {
				...tenant,
				isActive: false,
			});
		},
	};
};
