import React, {
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
  ButtonHTMLAttributes,
} from "react";
import classes from "./Modal.module.scss";

export interface Props {
  isOpen: boolean;
  title: string;
  acceptButton?: ButtonHTMLAttributes<string>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Modal(props: PropsWithChildren<Props>) {
  const closeDialog = () => props.setIsOpen(false);
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return (
    <>
      {props.isOpen && (
        <span className={classes.dialog_backdrop} onClick={closeDialog}>
          <div className={classes.dialog} onClick={stopPropagation}>
            <header>
              <h1>{props.title}</h1>
            </header>
            <div className={classes.dialog_content}>{props.children}</div>
            <footer>
              <button
                onClick={closeDialog}
                className="button is-danger is-light is-outlined"
              >
                Close
              </button>
              {props.acceptButton}
            </footer>
          </div>
        </span>
      )}
    </>
  );
}

export default Modal;
