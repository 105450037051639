import { DrawingManager } from "@react-google-maps/api";
import { Area, Point } from "dtos";

export interface DrawingProps {
  mode: google.maps.drawing.OverlayType;
  color?: string;
  icon?: string;
  addArea?: (area: Area) => void;
  addBase?: (at: Point) => void;
}

const DrawingTool: React.FC<DrawingProps> = ({
  mode,
  icon,
  addArea,
  color,
  addBase,
}) => {
  const addPolygon = (polygon: any) => {
    polygon.map = null;
    if (addArea) {
      addArea({
        points: polygon
          .getPath()
          .getArray()
          .map((latlng: any) => {
            return { lat: latlng.lat(), lng: latlng.lng() } as Point;
          }),
      } as Area);
    }
  };
  const addMarker = (marker: any) => {
    marker.setMap(null);
    if (addBase) {
      addBase({
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng(),
      } as Point);
    }
  };
  return (
    <DrawingManager
      drawingMode={mode}
      onPolygonComplete={(polygon) => {
        addPolygon(polygon);
      }}
      onMarkerComplete={(marker) => {
        addMarker(marker);
      }}
      options={{
        markerOptions: { icon },
        polygonOptions: {
          fillColor: color,
          strokeColor: color,
        },
        drawingControl: false,
      }}
    ></DrawingManager>
  );
};

export default DrawingTool;
