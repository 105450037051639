import axios from "axios";
import { Authentication, getAuth, setAuth } from "helpers/AuthWrapper";
import { Constants } from "helpers/Constants";
import jwtDecode from "jwt-decode";
import { useRecoilValue } from "recoil";
import { theTenant } from "myrecoil/TenantState";
import { userAtom } from "myrecoil/UserState";
import { useAuth } from "customhooks/useAuth";

export interface UserModel {
	sub: string;
	tenant_id: string[];
	user_role: string;
}

export const doApiCall = (
	apiCall: (headers: any, decodedUser: UserModel) => Promise<any>,
	logout: () => void
) => {
	let auth = getAuth();
	if (auth) {
		const headers = {
			Accept: "application/json",
			Authorization: `${auth.tokenType} ${auth.accessToken}`,
		};
		let user = jwtDecode(auth.accessToken);
		return apiCall(headers, user as UserModel).catch((error) => {
			if (error?.response?.status === 401) {
				return refreshToken(auth!.refreshToken)
					.then(({ data }) => {
						auth = data as Authentication;
						setAuth(auth);
						headers.Authorization = `${auth.tokenType} ${auth.accessToken}`;
						return apiCall(headers, user as UserModel);
					})
					.catch(() => {
						logout();
					});
			}
			throw error;
		});
	} else {
		return Promise.reject("Unauthorized");
	}
};

//TODO: one request at a time
const refreshToken = (token: string) => {
	if (token) {
		return axios.get(
			`${Constants.apiRoot}/connect/token?refresh_token=${token}`
		);
	} else {
		return Promise.reject("No refresh token.");
	}
};

export const useApiBase = (url: string) => {
	const auth = useAuth();

	//this function is used to logout if refresh token fails
	const doApiCallWrap = (
		apiCall: (headers: any, decodedUser: UserModel) => Promise<any>
	) => {
		return doApiCall(apiCall, () => {
			auth.logout();
		});
	};

	return {
		get: (resources: string) => {
			return doApiCallWrap((headers) => {
				return axios.get(url + resources, { headers });
			});
		},
		post: (resources: string, data: any) => {
			return doApiCallWrap((headers: any) => {
				return axios.post(url + resources, data, {
					headers,
				});
			});
		},
		patch: (resources: string, data: any) => {
			return doApiCallWrap((headers: any) => {
				return axios.patch(url + resources, data, {
					headers,
				});
			});
		},
		delete: (resources: string) => {
			return doApiCallWrap((headers: any) => {
				return axios.delete(url + resources, { headers });
			});
		},
	};
};

export const useApi = () => {
	const tenant = useRecoilValue(theTenant);
	const theUser = useRecoilValue(userAtom);

	return useApiBase(
		`${Constants.apiRoot}/api/${Constants.apiVersion}/tenants/${
			tenant!.id
		}/users/${theUser!.sub}/`
	);
};
