import { Tenant } from "dtos/Tenant";
import { FaEdit, FaTrash } from "react-icons/fa";

interface Props {
	tenant: Tenant;
	viewTenant: (tenant: Tenant) => void;
	deleteTenant: (tenant: Tenant) => void;
}

const TenantRow: React.FC<Props> = ({ tenant, viewTenant, deleteTenant }) => {
	return (
		<div className="Rtable Rtable--4cols">
			<div className="Rtable-cell">{tenant.name}</div>
			<div className="Rtable-cell">{tenant.email}</div>
			<div className="Rtable-cell only-wide-screen">
				{tenant.description}
			</div>
			<div className="Rtable-cell">
				<span
					title="Edit Company"
					style={{ marginLeft: "2px" }}
					className="button is-primary is-light is-outlined"
					onClick={() => {
						viewTenant(tenant);
					}}
				>
					<FaEdit size={20}></FaEdit>
				</span>
				<span
					title="Delete Company"
					style={{ marginLeft: "2px" }}
					className="button is-danger is-light is-outlined"
					onClick={() => {
						deleteTenant(tenant);
					}}
				>
					<FaTrash size={20}></FaTrash>
				</span>
			</div>
		</div>
	);
};

export default TenantRow;
