import ReportViewer from "components/ReportViewer";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router";

const ReportPage: React.FC = () => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);

	let reportName = query.get("reportName");
	let id = query.get("id");
	let isLight = query.get("isLight");

	return (
		<section className="hero is-fullheight">
			<div className="hero-body dynamic-theme-body ">
				<main className="container is-align-self-flex-start">
					<div style={{ width: "100%", height: "70em" }}>
						{id && (<ReportViewer reportName={reportName!} assignmentId={id!} isLight={isLight === "true"}/>)}
					</div>
				</main>
			</div>
		</section>
	);
};

export default ReportPage;
