import { setAuth } from "helpers/AuthWrapper";
import { theTenant } from "myrecoil/TenantState";
import { userAtom } from "myrecoil/UserState";
import { useSetRecoilState } from "recoil";

export const useAuth = () => {
	const setTenant = useSetRecoilState(theTenant);
	const setUser = useSetRecoilState(userAtom);

	return {
		logout: () => {
			setTenant(null);
			setAuth(null);
			setUser(null);
		},
	};
};
