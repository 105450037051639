import { AssignmentTable } from "dtos/AssignmentTable";
import { atom } from "recoil";

export const selectedTable = atom<{
	table: AssignmentTable;
	area: number;
	row: number;
	col: number;
} | null>({
	key: "assignment.selectedTable",
	default: null,
});

export const loadingAssignmentAtom = atom<boolean>({
	key: "assignment.loading",
	default: false,
});

export const loadingAssignmentErrorAtom = atom<string | null>({
	key: "assignment.loading.error",
	default: null,
});
