import { FC } from "react";

interface Props {
  completed: number;
}
const ProgressBar: FC<Props> = ({ completed }) => {
  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: 50,
    border: "1px solid black",
  };

  return (
    <div style={containerStyles}>
      <div
        style={{
          height: "100%",
          width: `${completed}%`,
          borderRadius: "inherit",
          textAlign: "right",
          backgroundColor:
            completed < 50 ? "#f14668" : completed < 75 ? "#fff6d1" : "#48c774",
        }}
      >
        <span
          style={{ fontWeight: "bold", position: "relative", top: "-5px" }}
        >{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
