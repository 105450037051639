import { DroneNetworkSettings } from "dtos/DroneNetworkSettings";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";

const labelStyle = {
  color: "#363636",
  display: "block",
  fontSize: "1rem",
  fontWeight: "bold",
} as const;

interface Props {
  selectedNetwork: DroneNetworkSettings;
  saveNetwork: (network: DroneNetworkSettings) => void;
  closeModal: () => void;
  deleteNetwork: (network: DroneNetworkSettings) => void;
}
const NetworkSettingsModal: React.FC<Props> = ({
  selectedNetwork,
  saveNetwork,
  closeModal,
  deleteNetwork,
}) => {
  const [network, setNetwork] = useState<DroneNetworkSettings>(selectedNetwork);
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.type === "checkbox") {
      return setNetwork({ ...network, [evt.target.name]: evt.target.checked });
    }
    setNetwork({ ...network, [evt.target.name]: evt.target.value });
  };

  const onSave = () => {
    saveNetwork(network);
    closeModal();
  };

  return (
    <div className="modal is-active is-clipped">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Network Settings</p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="columns">
            <div className="column is-flex is-justify-content-center">
              <div className="pretty p-default p-round p-thick p-smooth mb-2">
                <input
                  type="checkbox"
                  onChange={handleChange}
                  name="useDhcp"
                  checked={network.useDhcp}
                />
                <div className="state p-primary-o">
                  <label>Use DHCP</label>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column  is-half ">
              <div className="field ">
                <label style={labelStyle}>Name: </label>
                <input
                  className="input "
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={network.name}
                  placeholder="Pick Unique Network Name"
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>dnsHostname: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  name="dnsHostname"
                  value={network.dnsHostname}
                  disabled={network.useDhcp}
                  placeholder="DNS Hostname"
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>IP Address: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  name="ipAddress"
                  value={network.ipAddress}
                  placeholder="IP Address"
                  disabled={network.useDhcp}
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>Subnet Mask: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  name="subnetMask"
                  value={network.subnetMask}
                  placeholder="Subnet Mask"
                  disabled={network.useDhcp}
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>Network Priority: </label>
                <input
                  className="input "
                  type="number"
                  step="1"
                  name="order"
                  value={network.order}
                  placeholder="Network Priority"
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="column is-half ">
              {" "}
              <div className="field ">
                <label style={labelStyle}>SSID: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  value={network.ssId}
                  name="ssId"
                  placeholder="SSID"
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>Encryptions: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  value={network.encryption}
                  name="encryption"
                  placeholder="Encryption"
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>Password: </label>
                <input
                  className="input "
                  type="password"
                  onChange={handleChange}
                  value={network.password}
                  name="password"
                  placeholder="Password"
                  disabled={network.useDhcp}
                ></input>
              </div>
              <div className="field ">
                <label style={labelStyle}>Router: </label>
                <input
                  className="input "
                  type="text"
                  onChange={handleChange}
                  name="router"
                  value={network.router}
                  placeholder="Router"
                  disabled={network.useDhcp}
                ></input>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <div
            className="row mt-5 is-flex is-justify-content-space-between"
            style={{ width: "100%" }}
          >
            <div>
              <button
                className="button is-primary is-outlined"
                onClick={onSave}
              >
                Save changes
              </button>
              <button className="button is-danger is-outlined" onClick={onSave}>
                Cancel
              </button>
            </div>
            {network.id && (
              <span
                className="button is-danger is-light is-outlined"
                onClick={() => {
                  deleteNetwork(network);
                }}
              >
                <span className="mr-2">Delete</span> <FaTrash></FaTrash>
              </span>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default NetworkSettingsModal;
