import { Mission } from "dtos";
import { Assignment } from "dtos/Assignment";
import {
	getAllAssignmentStatuses,
	getEditableAssignmentStatuses,
} from "helpers/AssignmentUtils";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AssignmentStatusComponent from "./assignment/AssignmentStatusComponent";

const IconText = styled.span`
	@media (max-width: 1024px) {
		display: none;
	}
`;

interface Props {
	assignment: Assignment;
	mission: Mission;
	setAssignmentStatus: (assignment: Assignment, newStatus: string) => void;
	showStatus: boolean;
}

const AssignmentRow: React.FC<Props> = ({
	assignment,
	mission,
	setAssignmentStatus,
	showStatus,
}) => {
	let isStatusEditable = getEditableAssignmentStatuses().find(
		(status) => status.value === assignment.state
	);
	return (
		<div className={`Rtable Rtable--${showStatus ? 4 : 3}cols`}>
			<div className="Rtable-cell ">
				{mission ? mission.name : assignment.missionId}
			</div>
			{showStatus &&
				(isStatusEditable ? (
					<div
						className="Rtable-cell"
						style={{ overflow: "visible" }}
					>
						<AssignmentStatusComponent
							actualValue={assignment.state}
							updateStatus={(newStatus) => {
								setAssignmentStatus(assignment, newStatus);
							}}
						></AssignmentStatusComponent>
					</div>
				) : (
					<div className="Rtable-cell ">
						{
							getAllAssignmentStatuses().find((status) => {
								return status.value === assignment.state;
							})?.label
						}
					</div>
				))}
			<div className="Rtable-cell">
				{assignment.timeCompleted
					? new Date(assignment.timeCompleted).toLocaleDateString(
							"en-GB"
					  )
					: ""}
			</div>
			<div className="Rtable-cell">
				<Link
					to={`/inspection/${assignment.id}`}
					className="button is-primary is-light is-outlined"
				>
					<IconText className="mr-2">View</IconText>{" "}
					<FaEye size={20}></FaEye>
				</Link>
			</div>
		</div>
	);
};

export default AssignmentRow;
