import axios from "axios";
import Spinner from "components/Spinner";
import { Authentication, setAuth } from "helpers/AuthWrapper";
import { Constants } from "helpers/Constants";
import jwtDecode from "jwt-decode";
import { userAtom } from "myrecoil/UserState";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import logo from "public/images/moonlimit-logo-med.png";
import backgroundImage from "public/images/Login_BG.png";

const LoginPage: React.FC = () => {
	const [userName, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const setUser = useSetRecoilState(userAtom);
	const login = () => {
		setLoading(true);
		axios
			.post(`${Constants.apiRoot}/connect/token`, { userName, password })
			.then(({ data }) => {
				setAuth(data as Authentication);
				setUser(jwtDecode(data.accessToken));
				setLoading(false);
			})
			.catch((error) => {
				let errorMessage =
					error.response?.status === 400
						? "Username and password are invalid."
						: error.toString();
				setError(errorMessage);
				setLoading(false);
				setUserName("");
				setPassword("");
			});
	};

	const handleKeyPress = (event: any) => {
		if (
			event.key === "Enter" &&
			userName.length !== 0 &&
			password.length !== 0 &&
			!loading
		) {
			login();
		}
	};

	return (
		<section className="hero is-flex-grow-1">
			<div className="dashboard-hero-container">
				<div className="is-flex is-align-items-flex-end dashboard-hero">
					<div className="is-flex is-flex-direction-column is-align-items-flex-end p-5">
						<div className="logo p-3">
							<img
								alt=""
								src={logo}
								height="180px"
								width="250px"
							></img>
						</div>
						<div className="is-flex is-flex-direction-column is-justify-content-end  pt-5">
							<div className="mb-2 is-flex is-justify-content-end is-align-items-center">
								<span className="mr-3">
									<b>Username:</b>
								</span>
								<input
									type="email"
									placeholder="Username"
									value={userName}
									onChange={(event) => {
										setUserName(event.target.value);
									}}
									onKeyPress={handleKeyPress}
									style={{ width: "200px" }}
									className="input is-small"
								/>
							</div>
							{loading && <Spinner mrgTop="5px"></Spinner>}
							<div className="is-flex is-justify-content-end">
								<span className="mr-3">
									<b>Password:</b>
								</span>
								<input
									type="password"
									placeholder="Password"
									value={password}
									onChange={(event) => {
										setPassword(event.target.value);
									}}
									onKeyPress={handleKeyPress}
									style={{ width: "200px" }}
									className="input is-small"
								/>
							</div>
							{error && <p className="help is-danger">{error}</p>}
							<div className="is-flex is-justify-content-space-between is-align-items-center my-3">
								<button
									className="button is-primary"
									onClick={login}
									disabled={
										userName.length === 0 ||
										password.length === 0 ||
										loading
									}
								>
									Sign In
								</button>
								<a
									style={{ color: "white" }}
									className="dashboard-hero__forgot-password"
									href={`${Constants.identityServerRoot}/Account/ForgotPassword`}
								>
									Forgot Password
								</a>
							</div>
						</div>
					</div>
					<div
						style={{
							backgroundImage: `url(${backgroundImage})`,
						}}
						className="dashboard-hero__image"
					></div>
				</div>
			</div>
		</section>
	);
};

export default LoginPage;
