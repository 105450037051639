import { useApi } from "api/API";
import MoveMissionModal from "components/mission/MoveMissionModal";
import MissionRow from "components/MissionRow";
import Spinner from "components/Spinner";
import useMount from "customhooks/useMount";
import { Mission } from "dtos";
import { useState } from "react";
import { GiFlyingTarget } from "react-icons/gi";
import { Link } from "react-router-dom";

const MissionsView = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [missions, setMissions] = useState<Mission[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [movedMission, setMovedMission] = useState<Mission | null>(null);

	const api = useApi();

	const getAllMissions = () => {
		setLoading(true);
		api.get("missions")
			.then((serverResponse) => {
				setMissions(serverResponse.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				if (404 === error.response?.status) {
					setError("No missions.");
				} else {
					setError(error.toString());
				}
			});
	};

	useMount(() => {
		getAllMissions();
	});

	const deleteMission = (mission: Mission) => {
		api.delete(`missions/${mission.id}`).then(() => {
			getAllMissions();
		});
	};

	return (
		<div className="container ">
			<MoveMissionModal
				closeModal={() => {
					setMovedMission(null);
				}}
				mission={movedMission}
				moveMission={(mission: Mission, to: string) => {
					api.patch(`missions/${mission.id}/move/${to}`, null).then(
						() => getAllMissions()
					);
				}}
			></MoveMissionModal>
			<div className="buttons">
				<Link
					className="button is-primary is-light is-outlined"
					to="/mission"
				>
					<span>New Mission</span>
					<GiFlyingTarget size={28} className="ml-2"></GiFlyingTarget>
				</Link>
			</div>
			<div className="Rtable header Rtable--3cols">
				<div className="Rtable-cell ">Name</div>
				<div className="Rtable-cell only-wide-screen">Type</div>
				<div className="Rtable-cell">Options</div>
			</div>
			{loading ? (
				<Spinner mrgTop="65px"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				missions.map((mission: Mission) => {
					return (
						<MissionRow
							key={mission.id}
							mission={mission}
							moveMission={(mission: Mission) =>
								setMovedMission(mission)
							}
							deleteMission={deleteMission}
						/>
					);
				})
			)}
		</div>
	);
};

export default MissionsView;
