import Box from "components/Box";
import { Tenant } from "dtos";
import { useEffect, useState } from "react";

interface Props {
	company: Tenant | null;
	closeModal: () => void;
	saveCompany: (company: Tenant) => Promise<void>;
}

const CompanyModal: React.FC<Props> = ({
	company,
	closeModal,
	saveCompany,
}) => {
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [isActive, setIsActive] = useState<boolean>();
	const [error, setError] = useState<string | null>(null);

	const resetErrors = () => {
		setError(null);
	};

	useEffect(() => {
		if (company?.id) {
			setName(company.name);
			setEmail(company.email);
			setDescription(company.description);
			setIsActive(company.isActive);
		} else {
			setName("");
			setEmail("");
			setDescription("");
			setIsActive(true);
		}
		return () => {
			resetErrors();
		};
	}, [company]);

	return (
		<div className={`modal ${company ? " is-active is-clipped" : ""}`}>
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">
						{company?.id ? company.name : "Create New Company"}
					</p>
					<button
						className="delete"
						aria-label="close"
						onClick={closeModal}
					></button>
				</header>
				{error ? (
					<div style={{ width: "100%", textAlign: "center" }}>
						{error}
					</div>
				) : (
					<section className="modal-card-body">
						<Box>
							<div className="row pt-4">
								<div className="columns">
									<div className="column is-half">
										<div className="field">
											<label className="label">
												Name:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={name}
												placeholder="Name"
												onChange={(event) => {
													setName(event.target.value);
												}}
											></input>
										</div>
									</div>
									<div className="column is-half">
										<div className="field">
											<label className="label">
												Email:{" "}
											</label>
											<input
												className="input "
												type="text"
												value={email}
												placeholder="Name"
												onChange={(event) => {
													setEmail(
														event.target.value
													);
												}}
											></input>
										</div>
									</div>
								</div>
							</div>
							<div className="row pt-4">
								<div className="columns">
									<div className="column">
										<div className="field">
											<label className="label">
												Descrition:{" "}
											</label>
											<input
												className="input"
												value={description}
												placeholder="tag"
												onChange={(event) => {
													setDescription(
														event.target.value
													);
												}}
											></input>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-5 is-flex is-justify-content-space-between">
								<button
									onClick={() => {
										resetErrors();
										saveCompany({
											...company,
											name,
											email,
											description,
											isActive,
										} as Tenant).catch((error) => {
											setError(error.toString());
										});
									}}
									className="mr-2 button is-primary is-outlined"
								>
									Save
								</button>
							</div>
						</Box>
					</section>
				)}
			</div>
		</div>
	);
};

export default CompanyModal;
