import { UserModel } from "api/API";
import { userAtom } from "myrecoil/UserState";
import { useRecoilState } from "recoil";

interface Props {
    allowedRoles: string[];
    AlternativeComponents: React.FC;
}

const UserRoleGate: React.FC<Props> = ({ children, allowedRoles, AlternativeComponents }) => {
	const [currentUser] = useRecoilState<UserModel | null>(userAtom);
	return (
		<>
			{currentUser && allowedRoles.includes(currentUser.user_role)
				? children
				: (<AlternativeComponents></AlternativeComponents>)}
		</>
	);
};

export default UserRoleGate;
