import { useApi } from "api/API";
import {
  DroneInfo,
  DroneSettingsNavigation,
  NetworkSettings,
  OnvifSettings
} from "components/drone";
import Spinner from "components/Spinner";
import useMount from "customhooks/useMount";
import { Drone } from "dtos";
import { useState } from "react";
import { FaAngleLeft, FaTrash } from "react-icons/fa";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router";

const DroneSettingsView: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  let url = useLocation().pathname.split("/");
  let history = useHistory();

  const [drone, setDrone] = useState<Drone>({
    name: "",
    owner: "",
    token: "",
    onvifSettings: {},
  } as Drone);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const api = useApi();

  useMount(() => {
    if (id && id !== "undefined") {
      api
        .get(`drones/${id}`)
        .then((result) => {
          if (result.data) {
            if (!result.data.onvifSettings) {
              result.data.onvifSettings = {};
            }
            setDrone(result.data);
          }
          setLoading(false);
        })
        .catch((e) => {
          setError(e.toString());
          setLoading(false);
        });
    } else {
      setRedirect(true);
    }
  });

  const save = () => {
    if (drone.id) {
      api
        .patch(`drones/${drone.id}`, drone)
        .then((result) => {
          console.log(result);
          history.push("/drones");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleDelete = (drone: Drone) => {
    api.delete(`drones/${drone.id}`).then(() => {
      history.push("/drones");
    });
  };

  return (
    <>
      {redirect && <Redirect to="/drones"></Redirect>}
      {loading ? (
        <Spinner></Spinner>
      ) : error ? (
        <div style={{width: "100%", textAlign: "center"}}>{error}</div>
      ) : (
        <>
          <div className={`modal ${showPrompt ? " is-active is-clipped" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Внимание!</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setShowPrompt(false);
                  }}
                ></button>
              </header>
              <section className="modal-card-body">
                <h5>Сигурни ли сте че искате да изтриете дрона?</h5>
              </section>
              <footer className="modal-card-foot">
                <button
                  className="button is-primary is-outlined"
                  onClick={() => {
                    handleDelete(drone);
                  }}
                >
                  Delete
                </button>
                <button
                  className="button is-danger is-outlined"
                  onClick={() => {
                    setShowPrompt(false);
                  }}
                >
                  Cancel
                </button>
              </footer>
            </div>
          </div>
          <div className="container">
            <div style={{ textAlign: "start", marginBottom: "5px" }}>
              <button
                onClick={() => {
                  history.push("/drones");
                }}
                className="button is-inverted"
              >
                <FaAngleLeft></FaAngleLeft>Back
              </button>
            </div>
          </div>
          <div className="container box dynamic-theme-content">
            <DroneSettingsNavigation
              droneId={drone.id}
              path={url}
            ></DroneSettingsNavigation>
            <div>
              <div className="row">
                <Switch>
                  <Route
                    path={`/drone/settings/${drone.id}/info`}
                    render={() => (
                      <DroneInfo drone={drone} setDrone={setDrone} />
                    )}
                  />

                  <Route
                    path={`/drone/settings/${drone.id}/network-settings`}
                    render={() => (
                      <NetworkSettings droneId={drone.id}></NetworkSettings>
                    )}
                  />
                  <Route
                    path={`/drone/settings/${drone.id}/onvif-settings`}
                    render={() => (
                      <OnvifSettings
                        drone={drone}
                        setDrone={setDrone}
                      ></OnvifSettings>
                    )}
                  />
                  <Redirect to={`/drone/settings/${drone.id}/info`} />
                </Switch>
              </div>
              <div className="row mt-5 is-flex is-justify-content-space-between">
                <span
                  onClick={save}
                  className="button is-primary is-light is-outlined"
                >
                  Save
                </span>
                {drone.id && (
                  <span
                    className="button is-danger is-light is-outlined"
                    onClick={() => {
                      setShowPrompt(true);
                    }}
                  >
                    <span className="mr-2">Delete</span> <FaTrash></FaTrash>
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DroneSettingsView;
