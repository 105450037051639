import { useApi } from "api/API";
import useInterval from "customhooks/useInterval";
import { Drone } from "dtos";
import { isOnline } from "helpers/DroneUtils";
import { getMissionType } from "helpers/MissionTypes";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FcSettings } from "react-icons/fc";
import { Link } from "react-router-dom";
import styled from "styled-components";

const IconText = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`;

interface Props {
  drone: Drone;
}

const DroneRow: React.FC<Props> = ({ drone }) => {
  const [myDrone, setMyDrone] = useState<Drone>(drone);
  const [online, setOnline] = useState<boolean>(isOnline(myDrone));
  
  const api = useApi();

  useInterval(() => {
    api.get(`drones/${myDrone.id}`).then(({ data }) => {
      if (data) {
        setMyDrone(data);
        setOnline(isOnline(data));
      }
    });
  }, 10000);

  return (
    <div className="Rtable Rtable--7cols">
      <div className="Rtable-cell ">{myDrone.name}</div>
      <div className="Rtable-cell only-wide-screen">{myDrone.platformCode}</div>
      <div className="Rtable-cell only-wide-screen">{myDrone.onboardCode}</div>
      <div className="Rtable-cell">
        <span
          className={`tag is-outlined is-light is-large ${
            online ? "is-primary" : "is-danger"
          }`}
        >
          {online ? "Online" : "Offline"}
        </span>
      </div>
      <div className="Rtable-cell only-wide-screen">
        {myDrone.assignedMission?.name}
      </div>
      <div className="Rtable-cell only-wide-screen">
        {myDrone.assignedMission &&
          getMissionType(myDrone.assignedMission.typeCode)?.name}
      </div>
      <div className="Rtable-cell">
        <Link
          to={`/drone/status/${myDrone.id}`}
          className="button is-primary is-light is-outlined"
        >
          <IconText className="mr-2">View</IconText> <FaEye size={20}></FaEye>
        </Link>
        <Link
          to={`/drone/settings/${myDrone.id}`}
          className="button is-info is-light is-outlined mr-1"
        >
          <IconText className="mr-2">Settings</IconText>{" "}
          <FcSettings size={20}></FcSettings>
        </Link>
      </div>
    </div>
  );
};

export default DroneRow;
