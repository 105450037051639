export interface Authentication {
	accessToken: string;
	refreshToken: string;
	tokenType: string;
}

const AUTH_KEY = "auth";

export const setAuth = (auth: Authentication | null) => {
	if (auth && auth.accessToken && auth.accessToken !== "") {
		localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
	} else {
		localStorage.removeItem(AUTH_KEY);
	}
};

export const getAuth: () => Authentication | null = () => {
	let authStr = localStorage.getItem(AUTH_KEY);
	return authStr ? (JSON.parse(authStr) as Authentication) : null;
};
