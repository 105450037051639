import { User } from "dtos/User";
import { Constants } from "helpers/Constants";
import { useApiBase } from "./API";

export const useUserApi = (tenant: string) => {
	const apiBase = useApiBase(
		`${Constants.apiRoot}/api/${Constants.apiVersion}/tenants/${tenant}/`
	);

	return {
		getAllUsers: () => {
			return apiBase.get("users").then(({ data }) => {
				if (Array.isArray(data)) {
					return data;
				}
				return [];
			});
		},
		saveUser: (user: User) => {
			if (user.id) {
				return apiBase.patch(`users/${user.id}`, user);
			} else {
				return apiBase.post(`users`, user);
			}
		},
		deleteUser: (user: User) => {
			return apiBase.delete(`users/${user.id}`);
		},
	};
};
