const Spinner = ({ mrgTop = "40%" }: { mrgTop?: string }) => {
  return (
    <div style={{ marginTop: mrgTop }} className="spinner">
      <div className="inner">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
