import Box from "components/Box";
import { ReportPosition } from "dtos/ReportPosition";

interface Props {
    reportPosition: ReportPosition;
}

const Telemetry: React.FC<Props> = ({ reportPosition }) => {
    const round = (num: number) => {
		return Math.round(num * 10) / 10;
	};
	return (
		<Box>
			<label className="label">Telemetry</label>
			<table className="table is-fullwidth">
				<thead>
					<tr>
						<th>Heading</th>
						<th>Altitude</th>
						<th>Speed</th>
						<th>Latitude</th>
						<th>Longitude</th>
						<th>GPS Quolity</th>
					</tr>
				</thead>
				<tbody>
                    <tr>
                        <td>{reportPosition.heading}</td>
                        <td>
							{reportPosition &&
							reportPosition.location &&
							reportPosition.location.height
								? round(reportPosition.location.height)
								: 0}
							m
                        </td>
                        <td>{reportPosition.speed}</td>
                        <td>{reportPosition.location?.lat}</td>
                        <td>{reportPosition.location?.lng}</td>
                    </tr>
				</tbody>
			</table>
		</Box>
	);
};

export default Telemetry;
