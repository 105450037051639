import { Link } from "react-router-dom";

import { FiWifi } from "react-icons/fi";
import { FaShieldAlt, FaIdCard } from "react-icons/fa";

const Navigation = ({
  droneId,
  path = [],
}: {
  droneId?: string;
  path: string[] | undefined;
}) => {
  return (
    <div className="container is-flex pb-4">
      <div className="sub_navigation is-flex is-flex-grow-1">
        <Link
          className={path.includes("info") ? "active" : ""}
          to={`/drone/settings/${droneId}/info`}
        >
          <div>
            <span className="  mr-2">
              {" "}
              <FaIdCard fontSize={"2.1rem"}></FaIdCard>
            </span>

            <span className=" ">Drone Info</span>
          </div>
        </Link>

        <Link
          className={path.includes("onvif-settings") ? "active" : ""}
          to={`/drone/settings/${droneId}/onvif-settings`}
        >
          <div>
            <span className=" ">
              {" "}
              <FaShieldAlt fontSize={"2.1rem"}></FaShieldAlt>
            </span>

            <span className=" ">ONVIF Settings</span>
          </div>
        </Link>
        <Link
          className={path.includes("network-settings") ? "active" : ""}
          to={`/drone/settings/${droneId}/network-settings`}
        >
          <div>
            <span className=" ">
              {" "}
              <FiWifi fontSize={"2.1rem"}></FiWifi>
            </span>

            <span className=" ">Network Settings</span>
          </div>
        </Link>
        <div style={{ flexGrow: 1 }}></div>
      </div>
    </div>
  );
};

export default Navigation;
