import { useMediaQuery } from "customhooks/useMediaQuery";
import { useState } from "react";
import { Drone } from "dtos";
import useMount from "customhooks/useMount";
import { useApi } from "api/API"
import Spinner from "components/Spinner";
import DroneRow from "components/DroneRow";
import { GiDeliveryDrone } from "react-icons/gi";
import { NewDroneModal } from "components/drone";

const DronesView = () => {
  const brakepoints = useMediaQuery();
  const [loading, setLoading] = useState<boolean>(true);
  const [drones, setDrones] = useState<Drone[]>([]);
  const [error, setError] = useState<string | null>(null);

  const api = useApi();
  
  const [newDroneModalActive, setNewDroneModalActive] =
    useState<boolean>(false);

  useMount(() => {
    api
      .get("drones")
      .then((serverResponse) => {
        setDrones(serverResponse.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (404 === error.response?.status) {
          setError("No drones.")
        } else {
          setError(error.toString());
        }
      });
  });

  return (
    <div className="container ">
      <NewDroneModal
        isActive={newDroneModalActive}
        closeModal={() => setNewDroneModalActive(false)}
      ></NewDroneModal>
      <div className="buttons">
        <button
          className="button is-primary is-light is-outlined"
          onClick={() => setNewDroneModalActive(true)}
        >
          <span>Add New </span>
          <GiDeliveryDrone className="ml-2"></GiDeliveryDrone>
        </button>
      </div>
      <div className="Rtable header Rtable--7cols">
        <div className="Rtable-cell ">Name</div>
        <div className="Rtable-cell only-wide-screen">Model</div>
        <div className="Rtable-cell only-wide-screen">CP Model</div>
        <div className="Rtable-cell">Status</div>
        <div className="Rtable-cell only-wide-screen">Mission Name</div>
        <div className="Rtable-cell only-wide-screen">Mission Type</div>
        <div className="Rtable-cell">
          {brakepoints?.md ? "View/Settings" : "Options"}
        </div>
      </div>
      {loading ? (
        <Spinner mrgTop="65px"></Spinner>
      ) : error ? (
        <div style={{width: "100%", textAlign: "center"}}>{error}</div>
      ) : (
        drones.map((drone: Drone) => {
          return <DroneRow key={drone.id} drone={drone} />;
        })
      )}
    </div>
  );
};

export default DronesView;
