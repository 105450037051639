import { Polygon } from "@react-google-maps/api";
import { AssignmentTable } from "dtos/AssignmentTable";
import { useRecoilState } from "recoil";
import { selectedTable as selectedTableAtom } from "myrecoil/AssignmentState";
import { useEffect } from "react";

interface Props {
	table: AssignmentTable;
	area: number;
	row: number;
	col: number;
}
const Table: React.FC<Props> = ({ table, row, col, area }) => {
	const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);
	let isSelected = selectedTable?.table === table;
	useEffect(() => () => setSelectedTable(null), []);
	const getStatusColor = (status: string) => {
		switch (status) {
			case "Ok":
				return "green";
			case "NotInspected":
				return "orange";
			default:
				return "crimson";
		}
	};
	return (
		<Polygon
			onClick={() => {
				setSelectedTable({ table, area, row, col });
			}}
			path={table.gps_Polygon}
			options={{
				strokeColor: isSelected
					? "white"
					: getStatusColor(table.inspectionStatus),
				fillColor: isSelected ? "white" : undefined,
			}}
		/>
	);
};
export default Table;
