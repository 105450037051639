import { Polygon } from "@react-google-maps/api";
import { Area } from "dtos/Area";

interface Props {
	areaInfo: Area;
}
const AreaMapView: React.FC<Props> = ({ areaInfo }) => {
	return (
		<Polygon
			path={areaInfo.points}
			options={{ strokeColor: "green", fillColor: undefined }}
		/>
	);
};
export default AreaMapView;
