import ReportDesigner from "components/ReportDesigner";

const ReportDesignerPage: React.FC = () => {
	return (
		<section className="hero is-fullheight">
			<div className="hero-body dynamic-theme-body ">
				<main className="container is-align-self-flex-start">
				    <div style={{ width: "100%", height: "70em" }}>
    					<ReportDesigner />
					</div>
				</main>
			</div>
		</section>
	);
};

export default ReportDesignerPage;
