import { PropsWithChildren } from "react";
import classes from './ListSection.module.scss'

interface Props {
    title: string
}

function ListSection(props: PropsWithChildren<Props>) {
    return (
        <section className={classes.list_section}>
            <h2>{props.title}</h2>
            {props.children}
        </section>
    )
}

export default ListSection;