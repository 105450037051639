import { DroneSystemStatus } from "dtos/DroneSystemStatus";

interface Props {
	systemsStatus: DroneSystemStatus[];
}

const SystemsStatusReport: React.FC<Props> = ({ systemsStatus }) => {
	const formatNumber = (n: number, dp: number) => {
		if (n === undefined) {
			return "n/a";
		}
		try {
			return n.toFixed(dp);
		} catch (ex) {
			return n;
		}
	};
	return (
		<>
			{systemsStatus.map((systemStatus) => {
				return (
					<div>
						{systemStatus && (
							<div>
								<label className="label">{`${systemStatus.role} System`}</label>
								<table className="table is-fullwidth">
									<thead>
										<tr>
											<th>System Status</th>
											<th>Message</th>
											<th>CPU Usage</th>
											<th>Temperature</th>
											<th>Memory Usage</th>
											<th>WiFi Signal</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{systemStatus.fault}</td>
											<td>{systemStatus.message}</td>
											<td>
												{formatNumber(
													systemStatus.cpu,
													1
												)}{" "}
												%
											</td>
											<td>
												{formatNumber(
													systemStatus.temp,
													0
												)}{" "}
												&deg;C
											</td>
											<td>
												{formatNumber(
													systemStatus.memory,
													1
												)}{" "}
												%
											</td>
											<td>
												{formatNumber(
													systemStatus.wiFiSignal,
													1
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
};

export default SystemsStatusReport;
