export module FlightState {
  export const FLIGHT_STATE_UNKNOWN = "Unknown";
  export const FLIGHT_STATE_LANDED = "Landed";
  export const FLIGHT_STATE_LANDING = "Landing";
  export const FLIGHT_STATE_TAKINGOFF = "Takeoff";
  export const FLIGHT_STATE_HOVERING = "Hover";
  export const FLIGHT_STATE_NAVIGATING = "Nav";
  export const FLIGHT_STATE_EMERGENCY = "Emergency";
  export const FLIGHT_STATE_MOTOR_RAMPING = "MotorsRamping";
  export const FLIGHT_STATE_EMERGENCY_LANDING = "EmergencyLanding";
}

export module NavActionType {
  export const NAV_ACTION_HOLD = 0;
  export const NAV_ACTION_TAKEOFF = 1;
  export const NAV_ACTION_LAND = 2;
  export const NAV_ACTION_LAND_APPROACH = 3;
  export const NAV_ACTION_RUNWAY_APPROACH = 4;
  export const NAV_ACTION_RTB = 5;
}

export module MissionState {
  export const MISSION_STATUS_UNKNOWN = 0;
  export const MISSION_STATUS_PENDING = 1;
  export const MISSION_STATUS_ACTIVE = 2;
  export const MISSION_STATUS_COMPLETE = 3;
  export const MISSION_STATUS_ABORTED = 4;
  export const MISSION_STATUS_NO_MISSION = 5;
  export const MISSION_STATUS_NO_ROUTE = 6;
}

export module Commands {
  export const ARM = 0;
  export const DISARM = 1;
  export const TAKEOFF = 2;
  export const LAND = 3;
  export const EMERGENCY_LAND = 4;
  export const EMERGENCY_STOP = 5;
  export const START_MISSION = 6;
  export const RETURN_HOME = 7;
  export const REBOOT = 8;
  export const SHUT_DOWN = 9;
}
