import Box from "components/Box";
import ProgressBar from "components/ProgressBar";
import { StatusReport } from "dtos";
import { getDroneState } from "helpers/DroneUtils";
import { SystemsStatusReport } from ".";

interface Props {
	statusReport: StatusReport;
}

const DroneStatusReport: React.FC<Props> = ({ statusReport }) => {
	return (
		<Box>
			<label className="label">Drone Status</label>
			<table className="table is-fullwidth">
				<thead>
					<tr>
						<th>Armed</th>
						<th>Disabled</th>
						<th>Failsafe</th>
						<th>Flight state</th>
						<th>Action</th>
						<th>Action completed</th>
						<th>Battery</th>
						<th>GPS Quality</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{statusReport.armed ? "true" : "false"}</td>
						<td>{statusReport.disabled ? "true" : "false"}</td>
						<td>{statusReport.failsafe ? "true" : "false"}</td>
						<td>{getDroneState(statusReport.droneStateType)}</td>
						<td>{statusReport.droneActionType}</td>
						<td>
							{statusReport.droneActionComplete
								? "true"
								: "false"}
						</td>
						<td>
							<ProgressBar
								completed={statusReport.battery}
							></ProgressBar>
						</td>
						<td>{statusReport.gpsQuality}</td>
					</tr>
				</tbody>
			</table>
			{statusReport.systemsStatus && (
				<SystemsStatusReport
					systemsStatus={statusReport.systemsStatus}
				></SystemsStatusReport>
			)}
		</Box>
	);
};

export default DroneStatusReport;
