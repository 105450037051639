import { useEffect } from "react";

/**
 * Use mount to execute callback on component mounting.
 *
 * @param {Function} callback
 * @returns {*}
 */
const useMount = (callback: () => void) => {
  useEffect(() => {
    callback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useMount;
