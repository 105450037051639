import Box from "components/Box";

interface Props {
	droneName?: string;
	missionName?: string;
}

const DroneStatusOffline: React.FC<Props> = ({
	droneName,
	missionName
}) => {
	return (
		<Box>
			<table className="table is-fullwidth">
				<thead>
					<tr>
						<th>Drone</th>
						<th>Mission</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{droneName}</td>
						<td>{missionName}</td>
						<td>
							<div className="buttons is-pulled-right">
								<div
									className={`is-danger tag is-medium is-light is-outlined`}
								>
									Offline
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</Box>
	);
};
export default DroneStatusOffline;
