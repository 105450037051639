import { Mission } from "dtos";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getMissionType } from "helpers/MissionTypes";
import { FaEye, FaTrash } from "react-icons/fa";
import { BiDoorOpen } from "react-icons/bi";

const IconText = styled.span`
	@media (max-width: 1024px) {
		display: none;
	}
`;

interface Props {
	mission: Mission;
	moveMission: (mission: Mission) => void;
	deleteMission: (mission: Mission) => void;
}

const MissionRow: React.FC<Props> = ({
	mission,
	deleteMission,
	moveMission,
}) => {
	return (
		<div className="Rtable Rtable--3cols">
			<div className="Rtable-cell ">{mission.name}</div>
			<div className="Rtable-cell">
				{getMissionType(mission.typeCode)?.name}
			</div>
			<div className="Rtable-cell">
				<Link
					to={`/mission/${mission.id}`}
					className="button is-primary is-light is-outlined mr-1"
				>
					<IconText className="mr-2">View</IconText>{" "}
					<FaEye size={20}></FaEye>
				</Link>
				<span
					title="Move to"
					className="button is-success is-light is-outlined"
					onClick={() => {
						moveMission(mission);
					}}
				>
					<IconText className="mr-2">Move</IconText>{" "}
					<BiDoorOpen size={20}></BiDoorOpen>
				</span>
				<span
					style={{marginLeft: "5px"}}
					className="button is-danger is-light is-outlined"
					onClick={() => {
						deleteMission(mission);
					}}
				>
					<IconText className="mr-2">Delete</IconText>{" "}
					<FaTrash size={20}></FaTrash>
				</span>
			</div>
		</div>
	);
};

export default MissionRow;
