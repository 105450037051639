import {
	getAllAssignmentStatuses,
	getAssignmentStatus,
	getEditableAssignmentStatuses,
} from "helpers/AssignmentUtils";
import { useEffect, useState } from "react";
import Select from "react-select";

interface Props {
	actualValue: string;
	updateStatus: (newValue: string) => void;
}

const AssignmentStatusComponent: React.FC<Props> = ({
	actualValue,
	updateStatus,
}) => {
	const [status, setStatus] = useState<{
		value: string;
		label: string;
	} | null>(getAssignmentStatus(actualValue));
	const [isChanged, setIsChanged] = useState<boolean>(false);

	useEffect(() => {
		setIsChanged(status?.value !== actualValue);
	}, [status, setStatus]);

	return (
		<div style={{ width: "80%" }}>
			<Select
				value={status}
				options={getEditableAssignmentStatuses()}
				onChange={(v: { value: string; label: string } | null) =>
					setStatus(v)
				}
				getOptionLabel={(v: { value: string; label: string }) =>
					v.label
				}
				getOptionValue={(v: { value: string; label: string }) =>
					v.value
				}
			></Select>
			{isChanged && (
				<div style={{marginTop: "5px"}}>
					<button
						onClick={() => {
							if (status) {
								updateStatus(status.value);
							}
						}}
						className="mr-2 button is-primary is-outlined"
					>
						Save
					</button>
					<button
						onClick={() => {
							setStatus(getAssignmentStatus(actualValue));
						}}
						className="mr-2 button is-danger is-outlined"
					>
						Discard
					</button>
				</div>
			)}
		</div>
	);
};

export default AssignmentStatusComponent;
