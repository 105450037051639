import styled from "styled-components";

export const Deck = styled.div`
	display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,350px));
    place-content: center;
`;

export const Card = styled.div`
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    margin: 10px;
    text-align: center;
`;

export const CardHead = styled.h4`
    font-weight: 400;
    padding: 5px;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
`;

export const CardBody = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
`;
