import { Area } from "dtos";

export const getAreaColor = (kind: number | string) => {
  switch (kind) {
    case 1:
    case "Avoid":
      return "#F08080"; //avoid
    case 2:
    case "Interest":
      return "#7CFC00"; //interest
    case 3:
    case "Mission":
      return "#FFD700"; //mission
    default:
      return "white"; //unknown
  }
};

export const generateAreaID = (area: Area) => {
  let id = area.id ? area.id : "area";
  area.points.forEach((point) => {
    id += `${point.lat}${point.lng}`;
  });
  return id;
};

export module AreaTypes {
  export const AVOID = {num: 1, str: "Avoid"}
  export const INTEREST = {num: 2, str: "Interest"};
  export const MISSION = {num: 3, str: "Mission"};
}