import { useApi } from "api/API"
import useMount from "customhooks/useMount";
import { Drone, Mission } from "dtos";
import { useState } from "react";
import Select from "react-select";
import { getMissionType } from "helpers/MissionTypes";
import Box from "components/Box";

interface Props {
  drone: Drone;
  setDrone: (drone: Drone) => void;
}

const DroneInfo: React.FC<Props> = ({ drone, setDrone }) => {
  const [allMissions, setAllMissions] = useState<Mission[]>([]);
  const api = useApi();
  useMount(() => {
    api
      .get("missions")
      .then(({ data }) => {
        if (data) {
          setAllMissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const handleMissionChanged = (mission: Mission | null) => {
    if (mission) {
      setDrone({
        ...drone,
        assignedMissionId: mission.id,
        assignedMission: null,
      });
    }
  };

  return (
    <Box>
      <div className="row pt-4">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">Name: </label>
              <input
                className="input "
                type="text"
                value={drone.name}
                placeholder="Name"
                onChange={(event) => {
                  setDrone({ ...drone, name: event.target.value });
                }}
              ></input>
            </div>
            <div className="field">
              <label className="label">Tag number: </label>
              <input
                className="input "
                type="text"
                value={drone.tagNumber}
                placeholder="tag"
                onChange={(event) => {
                  setDrone({ ...drone, tagNumber: event.target.value });
                }}
              ></input>
            </div>
            <div className="field">
              <label className="label">Drone Model: </label>
              <input
                className="input "
                type="text"
                value={drone.platformCode}
                placeholder="Drone Model"
                onChange={(event) => {
                  setDrone({ ...drone, platformCode: event.target.value });
                }}
              ></input>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label className="label">Owner: </label>
              <input
                className="input "
                type="text"
                value={drone.owner}
                placeholder="Owner"
                onChange={(event) => {
                  setDrone({ ...drone, owner: event.target.value });
                }}
              ></input>
            </div>

            <div className="field">
              <label className="label">Token: </label>
              <input
                className="input "
                type="text"
                value={drone.token}
                placeholder="token"
                onChange={(event) => {
                  setDrone({ ...drone, token: event.target.value });
                }}
                disabled={true}
              ></input>
            </div>
            <div className="field">
              <label className="label">Computer Model: </label>
              <input
                className="input "
                type="text"
                value={drone.onboardCode}
                placeholder="Computer Model"
                onChange={(event) => {
                  setDrone({ ...drone, onboardCode: event.target.value });
                }}
              ></input>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div className="field">
              <label className="label">Assigned Mission: </label>
              <Select
                placeholder={"Assign Mission"}
                className="select-drop"
                getOptionLabel={(mission: Mission) =>
                  mission.name + " - " + getMissionType(mission.typeCode)?.name
                }
                getOptionValue={(mission: Mission) => {
                  return mission.id;
                }}
                name="typeSelect"
                value={allMissions.find(
                  (mission) => mission.id === drone.assignedMissionId
                )}
                onChange={handleMissionChanged}
                options={allMissions}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DroneInfo;
