import { useEffect, useRef, useState } from "react";

interface Props {
	value: string;
	setValue: (newValue: string) => boolean;
}

const EditableParagraph: React.FC<Props> = ({ value, setValue }) => {
	const [tmp, setTmp] = useState<string>("");
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const inputRef = useRef<any>();

	useEffect(() => {
		setTmp(value);
	}, [value]);

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select();
		}
	}, [isEdit]);

	const doSetValue = (newValue: string) => {
		setIsEdit(false);
		if (!setValue(newValue)) {
			setTmp(value);
		}
	};

	return (
		<div
			style={{
				borderRadius: "10px",
				border: "2px solid black",
				padding: "3px",
				minWidth: "3em",
				fontSize: "1.25rem",
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				width: `${tmp.length}ch`,
				marginRight: "2px"
			}}
			onClick={() => {
				if (!isEdit) setIsEdit(true);
			}}
			title="Click to Edit"
		>
			{isEdit ? (
				<input
					ref={inputRef}
					value={tmp}
					style={{
						border: "none",
						borderWidth: "0px",
						outline: "none",
						backgroundColor: "transparent",
						width: `${tmp.length}ch`,
						fontSize: "inherit",
					}}
					onChange={(e) => {
						let v = e.target.value;
						e.target.style.width = `${
							v.length > 0 ? v.length : 1
						}em`;
						setTmp(v);
					}}
					onBlur={() => {
						doSetValue(tmp);
					}}
					onKeyPress={(event: any) => {
						if (event.key === "Enter") {
							doSetValue(tmp);
						}
					}}
				></input>
			) : (
				<span>{tmp}</span>
			)}
		</div>
	);
};

export default EditableParagraph;
