import Box from "components/Box";
import { Assignment, WeatherInfo } from "dtos/Assignment";
import { useState } from "react";

const labelStyle = {
  color: "#363636",
  display: "block",
  fontSize: "1rem",
  fontWeight: "bold",
} as const;

interface Props {
    selectedAssignment: Assignment | null;
    saveAssignment: (assignment: Assignment | null) => void;
    closeModal: () => void;
}
const SetWeatherPerAssignmentModal : React.FC<Props> = ({
    saveAssignment,
    selectedAssignment,
    closeModal
}) => {
    const [assignment, setAssignment] = useState<Assignment | null>(selectedAssignment );

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (assignment !== null)
        {
            setAssignment({ ...assignment, weatherInfo: { ...assignment.weatherInfo, [evt.target.name]: evt.target.value } });
        }
    };

  const onSave = () => {
      saveAssignment(assignment);
  };

  return (
    <div className="modal is-active is-clipped">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Weather Settings</p>
          <button
            className="delete"
                      aria-label="close"
                      onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
        <Box>
            <div className="row">
               <div className="field ">
                    <label style={labelStyle}>Humidity</label>
                    <input
                     className="input "
                     type="number"
                     name="humidity"
                     value={assignment?.weatherInfo?.humidity}
                     onChange={handleChange}
                    />
              </div>
            </div>
            <div className="row">
              <div className="field ">
                <label style={labelStyle}>Cloud Cover</label>
                <input
                  className="input "
                  type="text"
                  name="cloudCover"
                  value={assignment?.weatherInfo?.cloudCover}
                  placeholder="cloud cover "
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="field">
                <label style={labelStyle}>Temperature</label>
                <input
                  className="input "
                  type="number"
                  value={assignment?.weatherInfo?.temperature}
                    name="temperature"
                    placeholder=""
                    onChange={handleChange}
                ></input>
               </div>
            </div>
            <div className="row">
              <div className="field ">
                <label style={labelStyle}>Temperature Units </label>
                          <input
                              className="input "
                              type="text"
                              value={assignment?.weatherInfo?.temperatureUnits}
                              placeholder="C/F/..."
                              onChange={handleChange}
                              name="temperatureUnits"
                            />
              </div>
            </div>
            <div className="row">
              <div className="field">
                <label style={labelStyle}>Windspeed</label>
                <input
                  className="input "
                  type="number"
                  value={assignment?.weatherInfo?.windspeed}
                    name="windspeed"
                    placeholder=""
                    onChange={handleChange}
                ></input>
               </div>
            </div>
            <div className="row">
              <div className="field ">
                <label style={labelStyle}>Windspeed Units </label>
                          <input
                              className="input "
                              type="text"
                              value={assignment?.weatherInfo?.windspeedUnits}
                              placeholder="m/s, km/h,..."
                              onChange={handleChange}
                              name="windspeedUnits"
                            />
              </div>
            </div>
        </Box>
        </section>
        <footer className="modal-card-foot">
          <div
            className="row mt-5 is-flex is-justify-content-space-between"
            style={{ width: "100%" }}
          >
            <div>
              <button
                className="button is-primary is-outlined"
                onClick={onSave}
              >
                Save changes
              </button>
                <button className="button is-danger is-outlined" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SetWeatherPerAssignmentModal;
