import { UserModel } from "api/API";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const userAtom = atom<UserModel | null>({
	key: "user.user",
	default: null,
	effects_UNSTABLE: [persistAtom],
});
